import { forwardRef, useCallback, useEffect, useState } from "react";
import AdminInput from "./Controllers/AdminInput";

const ContactInfoEditForm = forwardRef(({ data, onSaveChanges }, ref) => {
  const [contactInfo, setContactInfo] = useState(data);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback((ev) => {
    ev.preventDefault();
    setSaving(true);
  }, [setSaving]);

  useEffect(() => {
    if (saving) {
      onSaveChanges(contactInfo);
      setSaving(false);
    }
  }, [saving]);

  useEffect(() => {
    setContactInfo(data);
  }, [data]);

  return (
    <form ref={ref} className="p-4" onSubmit={handleSave}>
      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Información de Contacto</legend>
        <div className="p-2">
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Título" source={contactInfo} path="title" value={contactInfo?.title || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="longtext" label="Descripción" source={contactInfo} path="description" value={contactInfo?.description || ""} />
        </div>

        <div className="p-2">
          <h2 className="text-xl mb-4">Dirección</h2>
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Título" source={contactInfo} path="addressTitle" value={contactInfo?.addressTitle || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Dirección" source={contactInfo} path="address" value={contactInfo?.address || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Ubicación" source={contactInfo} path="addressPlace" value={contactInfo?.addressPlace || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Localidad" source={contactInfo} path="addressLocation" value={contactInfo?.addressLocation || ""} />
        </div>

        <div className="p-2">
          <h2 className="text-xl mb-4">Teléfono</h2>
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Título" source={contactInfo} path="phoneTitle" value={contactInfo?.phoneTitle || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Número" source={contactInfo} path="phoneNumber" value={contactInfo?.phoneNumber || ""} />
        </div>
        <div className="p-2">
          <h2 className="text-xl mb-4">Horario de atención</h2>
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Título" source={contactInfo} path="businessHoursTitle" value={contactInfo?.businessHoursTitle || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Horario" source={contactInfo} path="businessHours" value={contactInfo?.businessHours || ""} />
        </div>
        <div className="p-2">
          <h2 className="text-xl mb-4">Correo electrónico</h2>
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Título" source={contactInfo} path="emailTitle" value={contactInfo?.emailTitle || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Email de contacto" source={contactInfo} path="email" value={contactInfo?.email || ""} />
        </div>
      </fieldset>
    </form>
  );
});

export default ContactInfoEditForm;
