const Input = ({ id, type, label, placeholder, value, onChange, error }) => {
  return (
    <div className="flex flex-col">
      <label htmlFor={id}>{label}</label>
      <input className={[
        "bg-gray-200",
        "py-1",
        "px-4",
        "rounded-full",
        error ? "border-2 border-red-700" : "border",
      ].join(" ")}
        value={value || ""}
        placeholder={placeholder || ""}
        onChange={onChange}
        type={type || "text"}
        name={id}
        id={id} />
      {error && <p className={[
        "text-xs",
        "text-center",
        "text-red-600",
        "py-1",
      ].join(" ")}>{error.message}</p>}
    </div>
  );
};

export default Input;
