import { Fragment, useCallback, useLayoutEffect, useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import Contact from "../components/Contact/Contact";
import Section from "../components/Section/Section";
import NotFound from "./NotFound";

const WealthManagement = ({ buttons, blocks, content }) => {
  const [contactInfo, setContactInfo] = useState({
    fullname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  useLayoutEffect(() => {
    const inViewport = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("inViewport", entry.isIntersecting);
        }
      });
    };

    const Obs = new IntersectionObserver(inViewport);
    const obsOptions = {
      threshold: 1
    };

    const elementsInViewport = document.querySelectorAll('[data-in-viewport]');

    elementsInViewport.forEach(element => {
      Obs.observe(element, obsOptions);
    });
  }, [content]);

  const handleFormSubject = useCallback((e) => {
    const fieldName = e.target.getAttribute('sendto');
    const fieldValue = e.target.getAttribute('sendvalue');

    setContactInfo({
      ...contactInfo,
      [fieldName]: fieldValue,
    });
  }, [contactInfo]);
  
  return (
    <>
      {!content && <NotFound content={blocks?.Page404} contact={{ info: blocks?.ContactInfo, form: blocks?.ContactForm }} />}
      {content && (
        <>
          <Section id={content?.idRef}
            background="bg-chroma-violet"
            textColor="text-white"
            className="pt-32"
          >
            {(content?.Hero?.title) && (
              <h1
                className="xs:text-3xl lg:text-5xl xs:text-center lg:text-left font-bold mb-8"
                data-in-viewport="scale-in"
                dangerouslySetInnerHTML={{ __html: content.Hero.title }}
              />
            )}

            {content?.Hero?.content?.length && (
              <div className="flex xs:flex-col lg:flex-row">
                <i className="xs:block lg:hidden icon-Nelcord text-10xl xs:w-full lg:w-1/2 xs:text-center xs:mb-8 lg:pr-8 xs:text-stock-background" data-in-viewport="scale-in"></i>
                <i className="xs:hidden lg:block icon-Nelcord text-20xl xs:w-full lg:w-1/2 xs:text-center xs:mb-8 lg:pr-8 text-stock-background" data-in-viewport="scale-in"></i>
                <div className="text-lg leading-8 text-white w-auto lg:pl-8 self-center" data-in-viewport="scale-in">
                  {content.Hero.content.map((item, index) => (
                    <Fragment key={index}>
                      {item.type === "paragraph" && (
                        <p
                          key={index}
                          className="mb-4"
                          dangerouslySetInnerHTML={{ __html: item.value }}
                        />
                      )}
                      {item.type === "list" && (
                        <ul className="list-disc ml-8 mb-4">
                          {item.values.map((listItem, listIndex) => 
                            listItem && (
                              <li
                                key={listIndex}
                                className="list-item"
                                data-in-viewport="scale-in"
                                dangerouslySetInnerHTML={{ __html: listItem }}
                              />
                            )
                          )}
                        </ul>
                      )}
                    </Fragment>
                  ))}
                  <div className="xs:block md:float-right">
                    <NavHashLink
                      to="#wealth-management-more"
                      className="py-2 px-8 uppercase border rounded-full xs:block xs:w-full md:inline md:w-auto text-center xs:mb-4 md:mr-4 md:mb-0"
                      data-in-viewport="scale-in"
                      dangerouslySetInnerHTML={ { __html: buttons?.MoreInfo?.text } }
                    />
                  </div>
                </div>
              </div>
            )}
          </Section>

          {content?.Body?.content?.length && (
            <Section
              id={content?.Body?.idRef}
              background="bg-white"
              textColor="text-gray-600"
              className="overflow-hidden"
            >
              {content.Body.content.map((item, index) => (
                <Fragment key={index}>
                  {item.type === "paragraph" && (
                    <p
                      key={index}
                      className="mb-8"
                      data-in-viewport="scale-in"
                      dangerouslySetInnerHTML={{ __html: item.value }}
                    />
                  )}
                  {item.type === "list" && (
                    <ul className="list-disc ml-8 mb-4">
                      {item.values.map((listItem, listIndex) => (
                        <li
                          key={listIndex}
                          className="list-item"
                          data-in-viewport="scale-in"
                          dangerouslySetInnerHTML={{ __html: listItem }}
                        />
                      ))}
                    </ul>
                  )}
                </Fragment>
              ))}
              <NavHashLink
                to="#contact-form"
                onClick={handleFormSubject}
                sendto="subject"
                sendvalue="Wealth Management"
                className={[
                  "py-2 px-8",
                  "uppercase",
                  "border",
                  "border-gray-600",
                  "rounded-full",
                  "xs:w-full",
                  "md:w-auto",
                  "float-right",
                  "flex",
                  "justify-center",
                  "items-center",
                ].join(" ")}
                data-in-viewport="scale-in"
                dangerouslySetInnerHTML={ { __html: buttons?.RequestAdvisor?.text } }
              />
            </Section>
          )}
        </>
      )}

      <Contact contactInfoTranslation={blocks?.ContactInfo} contactFormTranslation={blocks?.ContactForm} pushContactInfo={contactInfo} />
    </>
  );
};

export default WealthManagement;
