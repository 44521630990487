import { forwardRef, useCallback, useEffect, useState } from "react";
import AdminInput from "./Controllers/AdminInput";

const BenefitsEditForm = forwardRef(({ data, onSaveChanges }, ref) => {
  const [benefits, setBenefits] = useState(data);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback((ev) => {
    ev.preventDefault();
    setSaving(true);
  }, [setSaving]);

  useEffect(() => {
    if (saving) {
      onSaveChanges(benefits);
      setSaving(false);
    }
  }, [saving]);

  useEffect(() => {
    setBenefits(data);
  }, [data]);

  return (
    <form ref={ref} className="p-4" onSubmit={handleSave}>
      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Hero</legend>
        <div className="px-2">
          <h2 className="text-xl mb-4">Bloque</h2>
          <AdminInput isSaving={saving} onChange={setBenefits} type="text" label="Tipo" source={benefits} path="Hero.type" value={benefits?.Hero?.type || "block"} hidden />
          <AdminInput isSaving={saving} onChange={setBenefits} type="text" label="Identificador" source={benefits} path="Hero.idRef" value={benefits?.Hero?.idRef || ""} />
          <ul>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setBenefits} type="text" label="Título" source={benefits} path="Hero.title" value={benefits?.Hero?.title || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setBenefits} type="longtext" label="Descripción" source={benefits} path="Hero.description" value={benefits?.Hero?.description || ""} />
            </li>
          </ul>
        </div>
      </fieldset>

      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Cuerpo</legend>
        <div className="px-2">
          <h2 className="text-xl mb-4">Bloque</h2>
          <AdminInput isSaving={saving} onChange={setBenefits} type="text" label="Tipo" source={benefits} path="Body.type" value={benefits?.Body?.type || "block"} hidden />
          <AdminInput isSaving={saving} onChange={setBenefits} type="text" label="Identificador" source={benefits} path="Body.idRef" value={benefits?.Body?.idRef || ""} />
          <ul>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setBenefits} type="text" label="Título" source={benefits} path="Body.title" value={benefits?.Body?.title || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setBenefits} type="longtext" label="Descripción" source={benefits} path="Body.description" value={benefits?.Body?.description || ""} />
            </li>
          </ul>
          <h2 className="text-xl mb-4">Contenido</h2>
          <ul>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setBenefits} type="text" label="Tipo" source={benefits} path="Body.content.0.type" value={benefits?.Body?.content?.[0]?.type || "paragraph"} hidden />
              <AdminInput isSaving={saving} onChange={setBenefits} type="text" label="Párrafo" source={benefits} path="Body.content.0.value" value={benefits?.Body?.content?.[0]?.value || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setBenefits} type="text" label="Tipo" source={benefits} path="Body.content.1.type" value={benefits?.Body?.content?.[1]?.type || "paragraph"} hidden />
              <AdminInput isSaving={saving} onChange={setBenefits} type="text" label="Párrafo" source={benefits} path="Body.content.1.value" value={benefits?.Body?.content?.[1]?.value || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setBenefits} type="text" label="Tipo" source={benefits} path="Body.content.2.type" value={benefits?.Body?.content?.[2]?.type || "paragraph"} hidden />
              <AdminInput isSaving={saving} onChange={setBenefits} type="text" label="Párrafo" source={benefits} path="Body.content.2.value" value={benefits?.Body?.content?.[2]?.value || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setBenefits} type="text" label="Tipo" source={benefits} path="Body.content.3.type" value={benefits?.Body?.content?.[3]?.type || "paragraph"} hidden />
              <AdminInput isSaving={saving} onChange={setBenefits} type="text" label="Párrafo" source={benefits} path="Body.content.3.value" value={benefits?.Body?.content?.[3]?.value || ""} />
            </li>
          </ul>
        </div>
      </fieldset>
    </form>
  );
});

export default BenefitsEditForm;
