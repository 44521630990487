const Section = ({ id, title, background, textColor, disposition, children, ...props }) => {
  return (
    <section {...{
      [id && 'id']: id,
    }} className={
      [
        id,
        "relative",
        "z-10",
        background,
        textColor,
        props?.className
      ].join(' ')
    }>
      <div className={
        [
          "lg:container",
          "mx-auto",
          "xs:p-8",
          "xl:p-16",
          disposition && disposition === "flex" ? "xl:flex" : "",
          disposition && disposition === "grid" ? "xl:grid" : "",
          disposition === "grid" ? "xs:flex xs:flex-col xl:grid-cols-12" : "",
          disposition === "grid" ? "gap-6" : ""
        ].join(' ')
      }>
        {title && <h1 className="xs:text-3xl lg:text-5xl font-semibold xs:border-b xl:border-0 xl:border-r xl:w-1/2 xs:pb-8 xl:pb-0 xl:pr-16">{title}</h1>}
        {children}
      </div>
    </section>
  );
};

export default Section;
