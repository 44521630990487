import { forwardRef } from "react";
import ContactFormEditForm from "./Forms/ContactFormEditForm";
import ContactInfoEditForm from "./Forms/ContactInfoEditForm";
import FooterEditForm from "./Forms/FooterEditForm";
import Page404EditForm from "./Forms/Page404EditForm";

const FormEditBlock = forwardRef(({ data, onSaveChanges }, ref) => {
  return (
    <>
      {data.id === "ContactInfo" && (
        <ContactInfoEditForm ref={ref} data={data} onSaveChanges={onSaveChanges} />
      )}
      {data.id === "ContactForm" && (
        <ContactFormEditForm ref={ref} data={data} onSaveChanges={onSaveChanges}/>
        )}
      {data.id === "Footer" && (
        <FooterEditForm ref={ref} data={data} onSaveChanges={onSaveChanges}/>
      )}
      {data.id === "Page404" && (
        <Page404EditForm ref={ref} data={data} onSaveChanges={onSaveChanges}/>
      )}
    </>
  );
});

export default FormEditBlock;
