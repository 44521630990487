import { forwardRef, useCallback, useEffect, useState } from "react";
import AdminInput from "./Controllers/AdminInput";

const ContactFormEditForm = forwardRef(({ data, onSaveChanges }, ref) => {
  const [contactForm, setContactInfo] = useState(data);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback((ev) => {
    ev.preventDefault();
    setSaving(true);
  }, [setSaving]);

  useEffect(() => {
    if (saving) {
      onSaveChanges(contactForm);
      setSaving(false);
    }
  }, [saving]);

  useEffect(() => {
    setContactInfo(data);
  }, [data]);

  return (
    <form ref={ref} className="p-4" onSubmit={handleSave}>
      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Formulario de Contacto</legend>
        <div className="p-2">
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Título" source={contactForm} path="title" value={contactForm?.title || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Subtítulo" source={contactForm} path="subtitle" value={contactForm?.subtitle || ""} />
        </div>

        <div className="p-2">
          <h2 className="text-xl mb-4">Campo: identidad</h2>
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Etiqueta" source={contactForm} path="fields.0.label" value={contactForm?.fields?.[0].label || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Descripción" source={contactForm} path="fields.0.placeholder" value={contactForm?.fields?.[0].placeholder || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Texto de error" source={contactForm} path="fields.0.errorText" value={contactForm?.fields?.[0].errorText || ""} />
        </div>

        <div className="p-2">
          <h2 className="text-xl mb-4">Campo: E-Mail</h2>
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Etiqueta" source={contactForm} path="fields.1.label" value={contactForm?.fields?.[1].label || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Descripción" source={contactForm} path="fields.1.placeholder" value={contactForm?.fields?.[1].placeholder || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Texto de error" source={contactForm} path="fields.1.errorText" value={contactForm?.fields?.[1].errorText || ""} />
        </div>

        <div className="p-2">
          <h2 className="text-xl mb-4">Campo: Teléfono</h2>
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Etiqueta" source={contactForm} path="fields.2.label" value={contactForm?.fields?.[2].label || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Descripción" source={contactForm} path="fields.2.placeholder" value={contactForm?.fields?.[2].placeholder || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Texto de error" source={contactForm} path="fields.2.errorText" value={contactForm?.fields?.[2].errorText || ""} />
        </div>

        <div className="p-2">
          <h2 className="text-xl mb-4">Campo: Asunto</h2>
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Etiqueta" source={contactForm} path="fields.3.label" value={contactForm?.fields?.[3].label || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Descripción" source={contactForm} path="fields.3.placeholder" value={contactForm?.fields?.[3].placeholder || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Texto de error" source={contactForm} path="fields.3.errorText" value={contactForm?.fields?.[3].errorText || ""} />
        </div>

        <div className="p-2">
          <h2 className="text-xl mb-4">Campo: Mensaje</h2>
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Etiqueta" source={contactForm} path="fields.4.label" value={contactForm?.fields?.[4].label || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Descripción" source={contactForm} path="fields.4.placeholder" value={contactForm?.fields?.[4].placeholder || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Texto de error" source={contactForm} path="fields.4.errorText" value={contactForm?.fields?.[4].errorText || ""} />
        </div>

        <div className="p-2">
          <h2 className="text-xl mb-4">Botón: Enviar</h2>
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Etiqueta" source={contactForm} path="buttons.0.label" value={contactForm?.buttons?.[0].label || ""} />
        </div>

        <div className="p-2">
          <h2 className="text-xl mb-4">Feedback de envío de mensaje</h2>
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Mensaje enviado" source={contactForm} path="messageSuccess" value={contactForm?.messageSuccess || ""} />
          <AdminInput isSaving={saving} onChange={setContactInfo} type="text" label="Mensaje no enviado" source={contactForm} path="messageError" value={contactForm?.messageError || ""} />
        </div>

      </fieldset>
    </form>
  );
});

export default ContactFormEditForm;
