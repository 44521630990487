import { NavHashLink } from 'react-router-hash-link';
import './Hero.css';

const Hero = ({ idRef, titles, altTitle }) => {
  return (
    <section id={idRef} className="hero relative z-10 bg-semi-transparent">
      <div data-slide-wrapper className="container mx-auto min-w-full min-h-screen p-8 flex flex-col items-center justify-center">
        {titles && titles.map(title => 
          (
            <h1 key={title.toLowerCase().split(' ').join('-')}
              className={[
                "text-white",
                "text-center",
                "xs:text-3xl",
                "xl:text-5xl",
                "font-bold",
                "text-shadow-lg"
              ].join(' ')}
              data-slide="true">
                {title}
            </h1>
          )
        )}
        {altTitle && (
          <h1 key={altTitle.toLowerCase().split(' ').join('-')}
            className={[
              "text-white",
              "text-center",
              "xs:text-3xl",
              "xl:text-5xl",
              "font-bold",
              "text-shadow-lg"
            ].join(' ')}
          >
            {altTitle}
          </h1>
        )}
      </div>
      <NavHashLink to="#about-us" className="absolute left-1/2 -ml-1/2 bottom-8 text-center text-6xl font-bold text-white">
        <i className="icon-Arrow"></i>
      </NavHashLink>
    </section>
  );
}

export default Hero;
