import { forwardRef, useCallback, useEffect, useLayoutEffect, useState } from "react";
import AdminInput from "./Controllers/AdminInput";

const HomeEditForm = forwardRef(({ data, onSaveChanges }, ref) => {
  const [home, setHome] = useState(data);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback((ev) => {
    ev.preventDefault();
    setSaving(true);
  }, [setSaving]);

  useEffect(() => {
    if (saving) {
      onSaveChanges(home);
      setSaving(false);
    }
  }, [saving]);

  useLayoutEffect(() => {
    setHome(data);
  }, [data]);
  
  return (
    <form ref={ref} className="p-4" onSubmit={handleSave}>
      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Hero</legend>
        <div className="px-2">
          <h2 className="text-xl mb-4">Bloque</h2>
          <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Hero.type" value={home?.Hero?.type || "block"} hidden />
          <AdminInput isSaving={saving} onChange={setHome} type="text" label="Identificador" source={home} path="Hero.idRef" value={home?.Hero?.idRef || ""} />
          <h2 className="text-xl mb-4">Lista de Títulos</h2>
          <AdminInput isSaving={saving} onChange={setHome} type="text" label="Lista de títulos" source={home} path="Hero.content.0.type" value={home?.Hero?.content?.[0]?.type || "list"} hidden />
          <ul>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="text" label="Elemento de la lista" source={home} path="Hero.content.0.values.0" value={home?.Hero?.content?.[0]?.values?.[0] || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="text" label="Elemento de la lista" source={home} path="Hero.content.0.values.1" value={home?.Hero?.content?.[0]?.values?.[1] || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="text" label="Elemento de la lista" source={home} path="Hero.content.0.values.2" value={home?.Hero?.content?.[0]?.values?.[2] || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="text" label="Elemento de la lista" source={home} path="Hero.content.0.values.3" value={home?.Hero?.content?.[0]?.values?.[3] || ""} />
            </li>
          </ul>
        </div>
      </fieldset>

      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Acerca de</legend>
        <div className="px-2">
          <h2 className="text-xl mb-4">Bloque</h2>
          <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="AboutUs.type" value={home?.AboutUs?.type || "block"} hidden />
          <AdminInput isSaving={saving} onChange={setHome} type="text" label="Identificador" source={home} path="AboutUs.idRef" value={home?.AboutUs?.idRef || ""} />
          <ul>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="text" label="Título" source={home} path="AboutUs.title" value={home?.AboutUs?.title || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Descripción" source={home} path="AboutUs.description" value={home?.AboutUs?.description || ""} />
            </li>
          </ul>
          <h2 className="text-xl mb-4">Contenido</h2>
          <ul>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="AboutUs.content.0.type" value={home?.AboutUs?.content?.[0]?.type || "paragraph"} hidden />
              <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Párrafo" source={home} path="AboutUs.content.0.value" value={home?.AboutUs?.content?.[0]?.value || ""} />
            </li>
          </ul>
        </div>
      </fieldset>

      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Objetivos</legend>
        <div className="px-2">
          <h2 className="text-xl mb-4">Bloque</h2>
          <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Goals.type" value={home?.Goals?.type || "block"} hidden />
          <AdminInput isSaving={saving} onChange={setHome} type="text" label="Identificador" source={home} path="Goals.idRef" value={home?.Goals?.idRef || ""} />
          <ul>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="text" label="Título" source={home} path="Goals.title" value={home?.Goals?.title || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Descripción" source={home} path="Goals.description" value={home?.Goals?.description || ""} />
            </li>
          </ul>
          <h2 className="text-xl mb-4">Contenido</h2>
          <div className="flex flex-wrap">
            <div className="w-1/2">
              <div className="m-2 p-2 bg-gray-100">
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Goals.content.0.type" value={home?.Goals?.content?.[0]?.type || "card"} hidden />
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Identificador" source={home} path="Goals.content.0.idRef" value={home?.Goals?.content?.[0]?.idRef || ""} />
                <ul>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Título" source={home} path="Goals.content.0.title" value={home?.Goals?.content?.[0]?.title || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Clases de la tarjeta" source={home} path="Goals.content.0.class" value={home?.Goals?.content?.[0]?.class || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Clases del ícono" source={home} path="Goals.content.0.icon" value={home?.Goals?.content?.[0]?.icon || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Descripción" source={home} path="Goals.content.0.description" value={home?.Goals?.content?.[0]?.description || ""} />
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-1/2">
              <div className="m-2 p-2 bg-gray-100">
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Goals.content.1.type" value={home?.Goals?.content?.[1]?.type || "card"} hidden />
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Identificador" source={home} path="Goals.content.1.idRef" value={home?.Goals?.content?.[1]?.idRef || ""} />
                <ul>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Title" source={home} path="Goals.content.1.title" value={home?.Goals?.content?.[1]?.title || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Clases de la tarjeta" source={home} path="Goals.content.1.class" value={home?.Goals?.content?.[1]?.class || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Clases del ícono" source={home} path="Goals.content.1.icon" value={home?.Goals?.content?.[1]?.icon || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Descripción" source={home} path="Goals.content.1.description" value={home?.Goals?.content?.[1]?.description || ""} />
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-1/2">
              <div className="m-2 p-2 bg-gray-100">
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Goals.content.2.type" value={home?.Goals?.content?.[2]?.type || "card"} hidden />
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Identificador" source={home} path="Goals.content.2.idRef" value={home?.Goals?.content?.[2]?.idRef || ""} />
                <ul>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Título" source={home} path="Goals.content.2.title" value={home?.Goals?.content?.[2]?.title || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Clases de la tarjeta" source={home} path="Goals.content.2.class" value={home?.Goals?.content?.[2]?.class || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Clases del ícono" source={home} path="Goals.content.2.icon" value={home?.Goals?.content?.[2]?.icon || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Descripción" source={home} path="Goals.content.2.description" value={home?.Goals?.content?.[2]?.description || ""} />
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-1/2">
              <div className="m-2 p-2 bg-gray-100">
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Goals.content.3.type" value={home?.Goals?.content?.[3]?.type || "card"} hidden />
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Identificador" source={home} path="Goals.content.3.idRef" value={home?.Goals?.content?.[3]?.idRef || ""} />
                <ul>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Título" source={home} path="Goals.content.3.title" value={home?.Goals?.content?.[3]?.title || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Clases de la tarjeta" source={home} path="Goals.content.3.class" value={home?.Goals?.content?.[3]?.class || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Clases del ícono" source={home} path="Goals.content.3.icon" value={home?.Goals?.content?.[3]?.icon || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Descripción" source={home} path="Goals.content.3.description" value={home?.Goals?.content?.[3]?.description || ""} />
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-1/2">
              <div className="m-2 p-2 bg-gray-100">
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Goals.content.4.type" value={home?.Goals?.content?.[4]?.type || "card"} hidden />
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Identificador" source={home} path="Goals.content.4.idRef" value={home?.Goals?.content?.[4]?.idRef || ""} />
                <ul>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Título" source={home} path="Goals.content.4.title" value={home?.Goals?.content?.[4]?.title || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Clases de la tarjeta" source={home} path="Goals.content.4.class" value={home?.Goals?.content?.[4]?.class || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Clases del ícono" source={home} path="Goals.content.4.icon" value={home?.Goals?.content?.[4]?.icon || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Descripción" source={home} path="Goals.content.4.description" value={home?.Goals?.content?.[4]?.description || ""} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Wealth Management</legend>
        <div className="px-2">
          <h2 className="text-xl mb-4">Bloque</h2>
          <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Wealth.type" value={home?.Wealth?.type || "block"} hidden />
          <AdminInput isSaving={saving} onChange={setHome} type="text" label="Identificador" source={home} path="Wealth.idRef" value={home?.Wealth?.idRef || ""} />
          <ul>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="text" label="Título" source={home} path="Wealth.title" value={home?.Wealth?.title || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Descripción" source={home} path="Wealth.description" value={home?.Wealth?.description || ""} />
            </li>
          </ul>
          <h2 className="text-xl mb-4">Contenido</h2>
          <div className="m-2 p-2 bg-gray-100">
            <ul>
              <li>
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Wealth.content.0.type" value={home?.Wealth?.content?.[0]?.type || "paragraph"} hidden />
                <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Párrafo" source={home} path="Wealth.content.0.value" value={home?.Wealth?.content?.[0]?.value || ""} />
              </li>
              <li>
                <h2 className="text-xl mb-4">Lista</h2>
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Wealth.content.1.type" value={home?.Wealth?.content?.[1]?.type || "list"} hidden />
                <ul>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Elemento de la lista" source={home} path="Wealth.content.1.values.0" value={home?.Wealth?.content?.[1]?.values?.[0] || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Elemento de la lista" source={home} path="Wealth.content.1.values.1" value={home?.Wealth?.content?.[1]?.values?.[1] || ""} />
                  </li>
                </ul>
              </li>
              <li>
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Wealth.content.2.type" value={home?.Wealth?.content?.[2]?.type || "paragraph"} hidden />
                <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Párrafo" source={home} path="Wealth.content.2.value" value={home?.Wealth?.content?.[2]?.value || ""} />
              </li>
            </ul>
          </div>
        </div>
      </fieldset>

      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">¡Hágalo ud. Mismo!</legend>
        <div className="px-2">
          <h2 className="text-xl mb-4">Bloque</h2>
          <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="DoItYourself.type" value={home?.DoItYourself?.type || "block"} hidden />
          <AdminInput isSaving={saving} onChange={setHome} type="text" label="Identificador" source={home} path="DoItYourself.idRef" value={home?.DoItYourself?.idRef || ""} />
          <ul>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="text" label="Título" source={home} path="DoItYourself.title" value={home?.DoItYourself?.title || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Descripción" source={home} path="DoItYourself.description" value={home?.DoItYourself?.description || ""} />
            </li>
          </ul>
        </div>
      </fieldset>

      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Insurance Services</legend>
        <div className="px-2">
          <h2 className="text-xl mb-4">Bloque</h2>
          <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Insurance.type" value={home?.Insurance?.type || "block"} hidden />
          <AdminInput isSaving={saving} onChange={setHome} type="text" label="Identificador" source={home} path="Insurance.idRef" value={home?.Insurance?.idRef || ""} />
          <ul>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="text" label="Título" source={home} path="Insurance.title" value={home?.Insurance?.title || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHome} type="longtext" label="Descripción" source={home} path="Insurance.description" value={home?.Insurance?.description || ""} />
            </li>
          </ul>
          <h2 className="text-xl mb-4">Contenido</h2>
          <div className="m-2 p-2 bg-gray-100">
            <h2 className="text-xl mb-4">Tarjeta</h2>
            <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Insurance.content.0.type" value={home?.Insurance?.content?.[0]?.type || "card"} hidden />
            <AdminInput isSaving={saving} onChange={setHome} type="text" label="Identificador" source={home} path="Insurance.content.0.idRef" value={home?.Insurance?.content?.[0]?.idRef || ""} />
            <ul>
              <li>
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Título" source={home} path="Insurance.content.0.title" value={home?.Insurance?.content?.[0]?.title || ""} />
              </li>
              <li>
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Clase" source={home} path="Insurance.content.0.class" value={home?.Insurance?.content?.[0]?.class || ""} />
              </li>
              <li>
                <ul>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Insurance.content.0.content.0.type" value={home?.Insurance?.content?.[0]?.content?.[0]?.type || "paragraph"} hidden />
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Párrafo" source={home} path="Insurance.content.0.content.0.value" value={home?.Insurance?.content?.[0]?.content?.[0]?.value || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Insurance.content.0.content.1.type" value={home?.Insurance?.content?.[0]?.content?.[1]?.type || "paragraph"} hidden />
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Párrafo" source={home} path="Insurance.content.0.content.1.value" value={home?.Insurance?.content?.[0]?.content?.[1]?.value || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Insurance.content.0.content.2.type" value={home?.Insurance?.content?.[0]?.content?.[2]?.type || "paragraph"} hidden />
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Párrafo" source={home} path="Insurance.content.0.content.2.value" value={home?.Insurance?.content?.[0]?.content?.[2]?.value || ""} />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="m-2 p-2 bg-gray-100">
            <h2 className="text-xl mb-4">Tarjeta</h2>
            <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Insurance.content.1.type" value={home?.Insurance?.content?.[1]?.type || "card"} hidden />
            <AdminInput isSaving={saving} onChange={setHome} type="text" label="Identificador" source={home} path="Insurance.content.1.idRef" value={home?.Insurance?.content?.[1]?.idRef || ""} />
            <ul>
              <li>
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Título" source={home} path="Insurance.content.1.title" value={home?.Insurance?.content?.[1]?.title || ""} />
              </li>
              <li>
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Clase" source={home} path="Insurance.content.1.class" value={home?.Insurance?.content?.[1]?.class || ""} />
              </li>
              <li>
                <ul>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Insurance.content.1.content.0.type" value={home?.Insurance?.content?.[1]?.content?.[0]?.type || "paragraph"} hidden />
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Párrafo" source={home} path="Insurance.content.1.content.0.value" value={home?.Insurance?.content?.[1]?.content?.[0]?.value || ""} />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="m-2 p-2 bg-gray-100">
            <h2 className="text-xl mb-4">Tarjeta</h2>
            <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Insurance.content.2.type" value={home?.Insurance?.content?.[2]?.type || "card"} hidden />
            <AdminInput isSaving={saving} onChange={setHome} type="text" label="Identificador" source={home} path="Insurance.content.2.idRef" value={home?.Insurance?.content?.[2]?.idRef || ""} />
            <ul>
              <li>
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Título" source={home} path="Insurance.content.2.title" value={home?.Insurance?.content?.[2]?.title || ""} />
              </li>
              <li>
                <AdminInput isSaving={saving} onChange={setHome} type="text" label="Clase" source={home} path="Insurance.content.2.class" value={home?.Insurance?.content?.[2]?.class || ""} />
              </li>
              <li>
                <ul>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Insurance.content.2.content.0.type" value={home?.Insurance?.content?.[2]?.content?.[0]?.type || "paragraph"} hidden />
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Párrafo" source={home} path="Insurance.content.2.content.0.value" value={home?.Insurance?.content?.[2]?.content?.[0]?.value || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Insurance.content.2.content.1.type" value={home?.Insurance?.content?.[2]?.content?.[1]?.type || "paragraph"} hidden />
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Párrafo" source={home} path="Insurance.content.2.content.1.value" value={home?.Insurance?.content?.[2]?.content?.[1]?.value || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Tipo" source={home} path="Insurance.content.2.content.2.type" value={home?.Insurance?.content?.[2]?.content?.[2]?.type || "paragraph"} hidden />
                    <AdminInput isSaving={saving} onChange={setHome} type="text" label="Párrafo" source={home} path="Insurance.content.2.content.2.value" value={home?.Insurance?.content?.[2]?.content?.[2]?.value || ""} />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </fieldset>
    </form>
  );
});

export default HomeEditForm;
