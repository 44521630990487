import { forwardRef, useCallback, useEffect, useState } from "react";
import AdminInput from "./Controllers/AdminInput";

const Page404EditForm = forwardRef(({ data, onSaveChanges }, ref) => {
  const [page404, setPage404] = useState(data);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback((ev) => {
    ev.preventDefault();
    setSaving(true);
  }, [setSaving]);

  useEffect(() => {
    if (saving) {
      onSaveChanges(page404);
      setSaving(false);
    }
  }, [saving]);

  useEffect(() => {
    setPage404(data);
  }, [data]);

  return (
    <form ref={ref} className="p-4" onSubmit={handleSave}>
      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Página 404</legend>
        <div className="p-2">
          <AdminInput isSaving={saving} onChange={setPage404} type="longtext" label="Contenido" source={page404} path="content" value={page404?.content || ""} />
        </div>
      </fieldset>
    </form>
  );
});

export default Page404EditForm;
