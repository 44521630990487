import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const config = {
  apiKey: "AIzaSyC4CseAU5SBpikLsyUQsGUaFEijYuKt1A0",
  authDomain: "nelcord-api.firebaseapp.com",
  projectId: "nelcord-api",
  storageBucket: "nelcord-api.appspot.com",
  messagingSenderId: "180678813659",
  appId: "1:180678813659:web:b7e1f60c52d8b26298a9fe",
  measurementId: "G-JYNZQJCLR9"
};

firebase.initializeApp(config);
export default firebase.firestore();
