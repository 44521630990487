import { forwardRef, useCallback, useEffect, useState } from "react";
import AdminInput from "./Controllers/AdminInput";

const MoreInfoEditForm = forwardRef(({ data, onSaveChanges }, ref) => {
  const [moreInfo, setMoreInfo] = useState(data);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback((ev) => {
    ev.preventDefault();
    setSaving(true);
  }, [setSaving]);

  useEffect(() => {
    if (saving) {
      onSaveChanges(moreInfo);
      setSaving(false);
    }
  }, [saving]);

  useEffect(() => {
    setMoreInfo(data);
  }, [data]);

  return (
    <form ref={ref} className="p-4" onSubmit={handleSave}>
      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Más Información</legend>
        <div className="p-2">
          <AdminInput isSaving={saving} onChange={setMoreInfo} type="text" label="Texto" source={moreInfo} path="text" value={moreInfo?.text || ""} />
        </div>
      </fieldset>
    </form>
  );
});

export default MoreInfoEditForm;
