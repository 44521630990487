import { createRef, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import SelectList from '../FormControl/Controls/SelectList/SelectList';
import './Header.css';

const Header = ({ languages, language, onLanguageChange }) => {
  const [navigationIsVisible, setNavigationIsVisible] = useState(false);
  const [languageOptions, setLanguagesOptions] = useState([]);
  const [languageValue, setLanguageValue] = useState(null);
  const headerRef = createRef();

  const handleNavigation = useCallback(() => {
    setNavigationIsVisible(!navigationIsVisible);
  }, [navigationIsVisible]);

  useEffect(() => {
    if (languages.loading) return;
    setLanguagesOptions(languages.list.map(language => ({
      key: language.id,
      value: language.label,
      title: language.label,
    })));
    setLanguageValue(languages?.list?.find(lang => lang.code === language).label);
  }, [languages, language])

  useLayoutEffect(() => {
    const hlinks = headerRef.current.querySelectorAll('a:not([omit-handle-navigation])');
    hlinks.forEach(link => {
      link.addEventListener('click', handleNavigation);
    });
  }, [headerRef, handleNavigation]);

  return (
    <header ref={headerRef} className="fixed w-full z-50 bg-gradient-to-b from-gray-900 to-blue-900 text-white">
      <div className="xl:container mx-auto flex justify-between items-center p-4 pl-8">
        <nav id="nelcord">
          <Link to="/" omit-handle-navigation="true">
            <span className="logo-md xs:flex xl:block">
              <i className="icon-Nelcord text-4xl mr-2"></i>
              <span className="logo-text xs:flex xs:flex-col xl:inline">
                <span className="uppercase text-2xl font-semibold mr-1 xs:leading-none">Nelcord</span>
                <span className="text-xs font-thin">Financial Services</span>
              </span>
            </span>
          </Link>
        </nav>
        <nav id="principal" className="xs:hidden md:block">
          <ul className="services chroma-bars line-between flex">
            <li className="service xs:text-sm lg:text-base px-6 pt-8 pt-8 pb-4 uppercase gradient-h gradient-cold">
              <NavHashLink to="/#wealth-management">Wealth Management</NavHashLink>
            </li>
            <li className="service xs:text-sm lg:text-base px-6 pt-8 pb-4 uppercase gradient-h gradient-hot">
              <NavHashLink to="/#insurance-services">Insurance Services</NavHashLink>
            </li>
            <li className="service xs:text-sm lg:text-base pl-6 pr-8 pt-8 pb-4 uppercase relative">
              <NavHashLink to="/benefits">Nelcord <span className="text-green-500">Benefits</span></NavHashLink>
              <i className="icon-star absolute top-3 right-3 text-4/5xl text-chroma rotate-45"></i>
            </li>
            <li className="service xs:text-sm lg:text-base px-6 pt-4 pb-4 uppercase relative">
              <a href="https://nelcordbenefits.com" target="_blank" rel="noreferrer" className="text-4xl">
                <i className="material-icons text-4xl">
                  login
                </i>
              </a>
            </li>
          </ul>
        </nav>
        <nav id="language" className="xs:hidden md:block">
          <ul className="languages line-between flex items-baseline">
            <li className="lang p-8 pb-4 px-4 capitalize omit-line-between">
              {languages.loading && <span className="text-gray-500">...</span>}
              {!languages.loading && <SelectList
                data-list="languages"
                value={languageValue}
                onSelect={onLanguageChange}
                className={[
                  "w-28",
                  "text-right",
                  "pr-4",
                  "bg-transparent"
                ].join(" ")}
                listClassName={[
                  "absolute",
                  "w-auto",
                  "mt-2",
                  "right-0",
                  "bg-white",
                  "text-gray-500",
                  "shadow-lg",
                  "z-10",
                ].join(" ")}
                listItemClassName="hover:bg-gray-300 px-2 py-2"
                iconSettings={{
                  size: "2/3rem",
                  top: "1/3rem",
                  right: "0",
                }}
                disabled
                options={languageOptions}
              ></SelectList>}
            </li>
          </ul>
        </nav>
        <nav id="mobile" className="xs:block md:hidden">
          <ul className="line-between flex items-baseline">
            <li className="p-8 py-4 px-4">
              <a href="https://nelcordbenefits.com" target="_blank" rel="noreferrer" className="text-4xl">
                <i className="material-icons text-4xl">
                  login
                </i>
              </a>
            </li>
            <li className="p-8 py-4 px-4">
              <button onClick={handleNavigation} omit-handle-navigation="true">
                <i className="icon-Nelcord text-4xl block transform rotate-90"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>

      <div className={
        [
          (navigationIsVisible ? "xs:block" : "xs:hidden"),
          "md:hidden",
        ].join(' ')
      }>
        <nav id="mobile-first-principal">
          <ul className="languages line-between flex justify-end items-baseline pr-4 z">
            <li className="lang p-8 pb-4 px-4 uppercase omit-line-between">
              {languages.loading && <span className="text-gray-500">...</span>}
              {!languages.loading && <SelectList
                data-list="languages"
                value={languageValue}
                onSelect={onLanguageChange}
                className={[
                  "w-28",
                  "text-right",
                  "pr-4",
                  "bg-transparent"
                ].join(" ")}
                listClassName={[
                  "absolute",
                  "w-auto",
                  "mt-2",
                  "right-0",
                  "bg-white",
                  "text-gray-500",
                  "shadow-lg",
                  "z-10",
                ].join(" ")}
                listItemClassName="hover:bg-gray-300 px-2 py-2"
                iconSettings={{
                  size: "2/3rem",
                  top: "1/3rem",
                  right: "0",
                }}
                disabled
                options={languageOptions}
              ></SelectList>}
            </li>
          </ul>
          <ul className="services chroma-bars text-right space-y-2 py-4">
            <li className="service xs:text-sm lg:text-base p-8 pb-4 uppercase gradient-h gradient-cold gradient-right">
              <NavHashLink to="/#wealth-management">Wealth Management</NavHashLink>
            </li>
            <li className="service xs:text-sm lg:text-base p-8 pb-4 uppercase gradient-h gradient-hot gradient-right">
              <NavHashLink to="/#insurance-services">Insurance Services</NavHashLink>
            </li>
            <li className="service xs:text-sm lg:text-base p-8 pb-4 uppercase relative">
              <NavHashLink to="/benefits">Nelcord <span className="text-green-500">Benefits</span></NavHashLink>
              <i className="icon-star absolute top-3 right-3 text-4/5xl text-chroma rotate-45"></i>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
