import { forwardRef, useCallback, useEffect, useState } from "react";
import AdminInput from "./Controllers/AdminInput";

const WealthEditForm = forwardRef(({ data, onSaveChanges }, ref) => {
  const [wealth, setWealth] = useState(data);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback((ev) => {
    ev.preventDefault();
    setSaving(true);
  }, [setSaving]);

  useEffect(() => {
    if (saving) {
      onSaveChanges(wealth);
      setSaving(false);
    }
  }, [saving]);

  useEffect(() => {
    setWealth(data);
  }, [data]);

  return (
    <form ref={ref} className="p-4" onSubmit={handleSave}>
      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Hero</legend>
        <div className="px-2">
          <h2 className="text-xl mb-4">Bloque</h2>
          <AdminInput isSaving={saving} onChange={setWealth} type="text" label="Tipo" source={wealth} path="Hero.type" value={wealth?.Hero?.type || "block"} hidden />
          <AdminInput isSaving={saving} onChange={setWealth} type="text" label="Identificador" source={wealth} path="Hero.idRef" value={wealth?.Hero?.idRef || ""} />
          <ul>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setWealth} type="text" label="Título" source={wealth} path="Hero.title" value={wealth?.Hero?.title || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setWealth} type="longtext" label="Descripción" source={wealth} path="Hero.description" value={wealth?.Hero?.description || ""} />
            </li>
          </ul>
          <h2 className="text-xl mb-4">Contenido</h2>
          <div className="m-2 p-2 bg-gray-100">
            <ul>
              <li>
                <AdminInput isSaving={saving} onChange={setWealth} type="text" label="Tipo" source={wealth} path="Hero.content.0.type" value={wealth?.Hero?.content?.[0]?.type || "paragraph"} hidden />
                <AdminInput isSaving={saving} onChange={setWealth} type="longtext" label="Párrafo" source={wealth} path="Hero.content.0.value" value={wealth?.Hero?.content?.[0].value || ""} />
              </li>
              <li>
                <h2 className="text-xl mb-4">Lista</h2>
                <AdminInput isSaving={saving} onChange={setWealth} type="text" label="Tipo" source={wealth} path="Hero.content.1.type" value={wealth?.Hero?.content?.[1]?.type || "list"} hidden />
                <ul>
                  <li>
                    <AdminInput isSaving={saving} onChange={setWealth} type="longtext" label="Elemento de la lista" source={wealth} path="Hero.content.1.values.0" value={wealth?.Hero?.content[1].values[0] || ""} />
                  </li>
                  <li>
                    <AdminInput isSaving={saving} onChange={setWealth} type="longtext" label="Elemento de la lista" source={wealth} path="Hero.content.1.values.1" value={wealth?.Hero?.content[1].values[1] || ""} />
                  </li>
                </ul>
              </li>
              <li>
                <AdminInput isSaving={saving} onChange={setWealth} type="text" label="Tipo" source={wealth} path="Hero.content.2.type" value={wealth?.Hero?.content?.[2]?.type || "paragraph"} hidden />
                <AdminInput isSaving={saving} onChange={setWealth} type="longtext" label="Párrafo" source={wealth} path="Hero.content.2.value" value={wealth?.Hero?.content[2].value || ""} />
              </li>
            </ul>
          </div>
        </div>
      </fieldset>
      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Cuerpo</legend>
        <div className="px-2">
          <h2 className="text-xl mb-4">Bloque</h2>
          <AdminInput isSaving={saving} onChange={setWealth} type="text" label="Tipo" source={wealth} path="Body.type" value={wealth?.Body?.type || "block"} hidden />
          <AdminInput isSaving={saving} onChange={setWealth} type="text" label="Identificador" source={wealth} path="Body.idRef" value={wealth?.Body?.idRef || ""} />
          <ul>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setWealth} type="text" label="Tipo" source={wealth} path="Body.content.0.type" value={wealth?.Body?.content?.[0]?.type || "paragraph"} hidden />
              <AdminInput isSaving={saving} onChange={setWealth} type="longtext" label="Párrafo" source={wealth} path="Body.content.0.value" value={wealth?.Body?.content?.[0]?.value || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setWealth} type="text" label="Tipo" source={wealth} path="Body.content.1.type" value={wealth?.Body?.content?.[1]?.type || "paragraph"} hidden />
              <AdminInput isSaving={saving} onChange={setWealth} type="longtext" label="Párrafo" source={wealth} path="Body.content.1.value" value={wealth?.Body?.content?.[1]?.value || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setWealth} type="text" label="Tipo" source={wealth} path="Body.content.2.type" value={wealth?.Body?.content?.[2]?.type || "paragraph"} hidden />
              <AdminInput isSaving={saving} onChange={setWealth} type="longtext" label="Párrafo" source={wealth} path="Body.content.2.value" value={wealth?.Body?.content?.[2]?.value || ""} />
            </li>
          </ul>
        </div>
      </fieldset>
    </form>
  );
});

export default WealthEditForm;
