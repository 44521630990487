import { forwardRef, useCallback, useEffect, useState } from "react";
import AdminInput from "./Controllers/AdminInput";

const InsuranceEditForm = forwardRef(({ data, onSaveChanges }, ref) => {
  const [insurance, setHealth] = useState(data);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback((ev) => {
    ev.preventDefault();
    setSaving(true);
  }, [setSaving]);

  useEffect(() => {
    if (saving) {
      onSaveChanges(insurance);
      setSaving(false);
    }
  }, [saving]);

  useEffect(() => {
    setHealth(data);
  }, [data]);

  return (
    <form ref={ref} className="p-4" onSubmit={handleSave}>
      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Hero</legend>
        <div className="px-2">
          <h2 className="text-xl mb-4">Bloque</h2>
          <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Hero.type" value={insurance?.Hero?.type || "block"} hidden />
          <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Identificador" source={insurance} path="Hero.idRef" value={insurance?.Hero?.idRef || ""} />
          <ul>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Título" source={insurance} path="Hero.title" value={insurance?.Hero?.title || ""} />
            </li>
            <li className="flex flex-col">
              <AdminInput isSaving={saving} onChange={setHealth} type="longtext" label="Descripción" source={insurance} path="Hero.description" value={insurance?.Hero?.description || ""} />
            </li>
          </ul>
          <h2 className="text-xl mb-4">Contenido</h2>
          <div>
            <div className="m-2 p-2 bg-gray-100">
              <h2 className="text-xl mb-4">Tarjeta</h2>
              <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Hero.content.0.type" value={insurance?.Hero?.content?.[0]?.type || "card"} hidden />
              <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Identificador" source={insurance} path="Hero.content.0.idRef" value={insurance?.Hero?.content?.[0]?.idRef || ""} />
              <ul>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Título" source={insurance} path="Hero.content.0.title" value={insurance?.Hero?.content?.[0]?.title || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="longtext" label="Descripción" source={insurance} path="Hero.content.0.description" value={insurance?.Hero?.content?.[0]?.description || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Clase" source={insurance} path="Hero.content.0.class" value={insurance?.Hero?.content?.[0]?.class || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Hero.content.0.content.0.type" value={insurance?.Hero?.content?.[0]?.content?.[0]?.type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Hero.content.0.content.0.value" value={insurance?.Hero?.content?.[0]?.content?.[0]?.value || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Hero.content.0.content.1.type" value={insurance?.Hero?.content?.[0]?.content?.[1]?.type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Hero.content.0.content.1.value" value={insurance?.Hero?.content?.[0]?.content?.[1]?.value || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Hero.content.0.content.2.type" value={insurance?.Hero?.content?.[0]?.content?.[2]?.type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Hero.content.0.content.2.value" value={insurance?.Hero?.content?.[0]?.content?.[2]?.value || ""} />
                </li>
              </ul>
            </div>
            
            <div className="m-2 p-2 bg-gray-100">
              <h2 className="text-xl mb-4">Tarjeta</h2>
              <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Hero.content.1.type" value={insurance?.Hero?.content?.[1]?.type || "card"} hidden />
              <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Identificador" source={insurance} path="Hero.content.1.idRef" value={insurance?.Hero?.content?.[1]?.idRef || ""} />
              <ul>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Título" source={insurance} path="Hero.content.1.title" value={insurance?.Hero?.content?.[1]?.title || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="longtext" label="Descripción" source={insurance} path="Hero.content.1.description" value={insurance?.Hero?.content?.[1]?.description || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Clase" source={insurance} path="Hero.content.1.class" value={insurance?.Hero?.content?.[1]?.class || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Hero.content.1.content.0.type" value={insurance?.Hero?.content?.[1]?.content?.[0]?.type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Hero.content.1.content.0.value" value={insurance?.Hero?.content?.[1]?.content?.[0]?.value || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Hero.content.1.content.1.type" value={insurance?.Hero?.content?.[1]?.content?.[1]?.type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Hero.content.1.content.1.value" value={insurance?.Hero?.content?.[1]?.content?.[1]?.value || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Hero.content.1.content.2.type" value={insurance?.Hero?.content?.[1]?.content?.[2]?.type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Hero.content.1.content.2.value" value={insurance?.Hero?.content?.[1]?.content?.[2]?.value || ""} />
                </li>
              </ul>
            </div>

            <div className="m-2 p-2 bg-gray-100">
              <h2 className="text-xl mb-4">Tarjeta</h2>
              <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Hero.content.2.type" value={insurance?.Hero?.content?.[2]?.type || "card"} hidden />
              <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Identificador" source={insurance} path="Hero.content.2.idRef" value={insurance?.Hero?.content?.[2]?.idRef || ""} />
              <ul>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Título" source={insurance} path="Hero.content.2.title" value={insurance?.Hero?.content?.[2]?.title || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="longtext" label="Descripción" source={insurance} path="Hero.content.2.description" value={insurance?.Hero?.content?.[2]?.description || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Clase" source={insurance} path="Hero.content.2.class" value={insurance?.Hero?.content?.[2]?.class || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Hero.content.2.content.0.type" value={insurance?.Hero?.content?.[2]?.content?.[0]?.type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Hero.content.2.content.0.value" value={insurance?.Hero?.content?.[2]?.content?.[0]?.value || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Hero.content.2.content.0.type" value={insurance?.Hero?.content?.[2]?.content?.[1]?.type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Hero.content.2.content.1.value" value={insurance?.Hero?.content?.[2]?.content?.[1]?.value || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Hero.content.2.content.2.type" value={insurance?.Hero?.content?.[2]?.content?.[2]?.type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Hero.content.2.content.2.value" value={insurance?.Hero?.content?.[2]?.content?.[2]?.value || ""} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Cuerpo</legend>
        <div className="px-2">
          <h2 className="text-xl mb-4">Bloque</h2>
          <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Body.type" value={insurance?.Body?.type || "block"} hidden />
          <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Identificador" source={insurance} path="Body.idRef" value={insurance?.Body?.idRef || ""} />
          <div>
            <div className="m-2 p-2 bg-gray-100">
              <h2 className="text-xl mb-4">Tarjeta</h2>
              <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Body.content.0.type" value={insurance?.Body?.content?.[0]?.type || "card"} hidden />
              <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Identificador" source={insurance} path="Body.content.0.idRef" value={insurance?.Body?.content?.[0]?.idRef || ""} />
              <ul>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Título" source={insurance} path="Body.content.0.title" value={insurance?.Body?.content?.[0]?.title || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="longtext" label="Descripción" source={insurance} path="Body.content.0.description" value={insurance?.Body?.content?.[0]?.description || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Clase" source={insurance} path="Body.content.0.class" value={insurance?.Body?.content?.[0]?.class || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Body.content.0.content.0.type" value={insurance?.Body?.content?.[0]?.content?.[0].type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Body.content.0.content.0.value" value={insurance?.Body?.content?.[0]?.content?.[0]?.value || ""} />
                </li>
                <li>
                  <h2 className="text-xl mb-4">Lista</h2>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Body.content.0.content.1.type" value={insurance?.Body?.content?.[0]?.content?.[1].type || "list"} hidden />
                  <ul>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.0.content.1.values.0" value={insurance?.Body?.content?.[0]?.content?.[1]?.values[0] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.0.content.1.values.1" value={insurance?.Body?.content?.[0]?.content?.[1]?.values[1] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.0.content.1.values.2" value={insurance?.Body?.content?.[0]?.content?.[1]?.values[2] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.0.content.1.values.3" value={insurance?.Body?.content?.[0]?.content?.[1]?.values[3] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.0.content.1.values.4" value={insurance?.Body?.content?.[0]?.content?.[1]?.values[4] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.0.content.1.values.5" value={insurance?.Body?.content?.[0]?.content?.[1]?.values[5] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.0.content.1.values.6" value={insurance?.Body?.content?.[0]?.content?.[1]?.values[6] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.0.content.1.values.7" value={insurance?.Body?.content?.[0]?.content?.[1]?.values[7] || ""} />
                    </li>
                  </ul>
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Body.content.0.content.2.type" value={insurance?.Body?.content?.[0]?.content?.[2].type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Body.content.0.content.2.value" value={insurance?.Body?.content?.[0]?.content?.[2]?.value || ""} />
                </li>
              </ul>
            </div>
            
            <div className="m-2 p-2 bg-gray-100">
              <h2 className="text-xl mb-4">Tarjeta</h2>
              <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Body.content.1.type" value={insurance?.Body?.content?.[1]?.type || "card"} hidden />
              <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Identificador" source={insurance} path="Body.content.1.idRef" value={insurance?.Body?.content?.[1]?.idRef || ""} />
              <ul>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Título" source={insurance} path="Body.content.1.title" value={insurance?.Body?.content?.[1]?.title || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="longtext" label="Descripción" source={insurance} path="Body.content.1.description" value={insurance?.Body?.content?.[1]?.description || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Clase" source={insurance} path="Body.content.1.class" value={insurance?.Body?.content?.[1]?.class || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Body.content.1.content.0.type" value={insurance?.Body?.content?.[1]?.content?.[0]?.type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Body.content.1.content.0.value" value={insurance?.Body?.content?.[1]?.content?.[0]?.value || ""} />
                </li>
                <li>
                  <h2 className="text-xl mb-4">Lista</h2>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Body.content.1.content.1.type" value={insurance?.Body?.content?.[1]?.content?.[1]?.type || "list"} hidden />
                  <ul>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.1.content.1.values.0" value={insurance?.Body?.content?.[1]?.content?.[1]?.values[0] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.1.content.1.values.1" value={insurance?.Body?.content?.[1]?.content?.[1]?.values[1] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.1.content.1.values.2" value={insurance?.Body?.content?.[1]?.content?.[1]?.values[2] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.1.content.1.values.3" value={insurance?.Body?.content?.[1]?.content?.[1]?.values[3] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.1.content.1.values.4" value={insurance?.Body?.content?.[1]?.content?.[1]?.values[4] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.1.content.1.values.5" value={insurance?.Body?.content?.[1]?.content?.[1]?.values[5] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.1.content.1.values.6" value={insurance?.Body?.content?.[1]?.content?.[1]?.values[6] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.1.content.1.values.7" value={insurance?.Body?.content?.[1]?.content?.[1]?.values[7] || ""} />
                    </li>
                  </ul>
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Body.content.1.content.2.type" value={insurance?.Body?.content?.[1]?.content?.[2]?.type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Body.content.1.content.2.value" value={insurance?.Body?.content?.[1]?.content?.[2]?.value || ""} />
                </li>
              </ul>
            </div>

            <div className="m-2 p-2 bg-gray-100">
              <h2 className="text-xl mb-4">Tarjeta</h2>
              <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Body.content.2.type" value={insurance?.Body?.content?.[2]?.type || "card"} hidden />
              <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Identificador" source={insurance} path="Body.content.2.idRef" value={insurance?.Body?.content?.[2]?.idRef || ""} />
              <ul>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Título" source={insurance} path="Body.content.2.title" value={insurance?.Body?.content?.[2]?.title || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="longtext" label="Descripción" source={insurance} path="Body.content.2.description" value={insurance?.Body?.content?.[2]?.description || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Clase" source={insurance} path="Body.content.2.class" value={insurance?.Body?.content?.[2]?.class || ""} />
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Body.content.2.content.0.type" value={insurance?.Body?.content?.[2]?.content?.[0]?.type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Body.content.2.content.0.value" value={insurance?.Body?.content?.[2]?.content?.[0]?.value || ""} />
                </li>
                <li>
                  <h2 className="text-xl mb-4">Lista</h2>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Body.content.2.content.1.type" value={insurance?.Body?.content?.[2]?.content?.[1]?.type || "list"} hidden />
                  <ul>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.2.content.1.values.0" value={insurance?.Body?.content?.[2]?.content?.[1]?.values[0] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.2.content.1.values.1" value={insurance?.Body?.content?.[2]?.content?.[1]?.values[1] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.2.content.1.values.2" value={insurance?.Body?.content?.[2]?.content?.[1]?.values[2] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.2.content.1.values.3" value={insurance?.Body?.content?.[2]?.content?.[1]?.values[3] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.2.content.1.values.4" value={insurance?.Body?.content?.[2]?.content?.[1]?.values[4] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.2.content.1.values.5" value={insurance?.Body?.content?.[2]?.content?.[1]?.values[5] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.2.content.1.values.6" value={insurance?.Body?.content?.[2]?.content?.[1]?.values[6] || ""} />
                    </li>
                    <li className="pl-4">
                      <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Elemento de la lista" source={insurance} path="Body.content.2.content.1.values.7" value={insurance?.Body?.content?.[2]?.content?.[1]?.values[7] || ""} />
                    </li>
                  </ul>
                </li>
                <li>
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Tipo" source={insurance} path="Body.content.2.content.2.type" value={insurance?.Body?.content?.[2]?.content?.[2]?.type || "paragraph"} hidden />
                  <AdminInput isSaving={saving} onChange={setHealth} type="text" label="Párrafo " source={insurance} path="Body.content.2.content.2.value" value={insurance?.Body?.content?.[2]?.content?.[2]?.value || ""} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  );
});

export default InsuranceEditForm;
