import { useCallback, useState } from "react";
import './AdminBar.css';
import AdminLink from './AdminLink';
import AdminPanel from "./AdminPanel";

const AdminBar = ({ allContent, openPanel, onChange, languages, language }) => {
  const [isAdminOpen, setIsAdminOpen] = useState(openPanel);

  const handleAdminSection = useCallback(() => {
    setIsAdminOpen(!isAdminOpen);
  }, [isAdminOpen, setIsAdminOpen]);

  return (
    <>
      {isAdminOpen && <AdminPanel onChange={onChange} allContent={allContent} languages={languages} language={language} />}

      <aside className="admin flex flex-row fixed bottom-8 right-8 px-4 z-50 bg-white shadow-md border-2 rounded-full">
        <AdminLink action={handleAdminSection} className="text-gray-900 flex">
          <i className="material-icons mr-1 inline-block transform border-gray-900 font-bold">settings</i> <span className="inline-block">Gestionar</span>
        </AdminLink>
        <span className="flex items-center">|</span>
        <AdminLink url="/admin-logout" className="text-gray-900 flex">
          <span className="inline-block">Salir</span> <i className="material-icons ml-1 inline-block transform border-gray-900 font-bold">power_settings_new</i>
        </AdminLink>
      </aside>
    </>
  );
}

export default AdminBar;
