import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

const Footer = ({ content, contactInfo }) => {
  console.log(contactInfo);
  
  return (
    <footer className="relative z-10 bg-gray-200 text-gray-600">
      <div className="xs:bg-gray-50 lg:bg-white xs:px-8 lg:px-16 py-8 lg:pt-0">
        <p
          className="text-center text-md"
          dangerouslySetInnerHTML={{ __html: content?.tradeMark }}
        />
      </div>
      <div className="lg:container mx-auto xs:px-8 lg:px-16 py-8 text-md">
        <div className="divide-gray-600 flex flex-wrap items-start justify-center xs:divide-y xs:flex-col lg:divide-y-0 lg:divide-x lg:flex-row">
          <div className="leading-6 flex xs:w-full xs:flex-col xs:items-center xs:py-8 xs:px-4 lg:items-start lg:flex-row lg:w-1/3 lg:px-8">
            <span className="logo-lg xs:mb-6">
              <i className="icon-Nelcord text-chroma text-5xl" data-in-viewport="scale-in"></i>
            </span>
            <ul className="space-y-2 xs:text-center lg:text-left lg:ml-6">
              <li>
                <NavHashLink to="#hero" className="font-bold">
                  {content?.navigation?.title}
                </NavHashLink>
              </li>
              <li>
                <Link to="/">{content?.navigation?.links[0]}</Link>
              </li>
              <li>
                <Link to="/wealth-management">{content?.navigation?.links[1]}</Link>
              </li>
              <li>
                <Link to="/insurance-services">{content?.navigation?.links[2]}</Link>
              </li>
              <li>
                <NavHashLink to="#contact">{content?.navigation?.links[3]}</NavHashLink>
              </li>
            </ul>
          </div>
          <div className="leading-6 xs:w-full xs:py-8 xs:px-4 lg:w-1/3 lg:px-8">
            <ul className="space-y-2">
              <li>
                <a href="mailto:info@nelcord.com.uy" className="xs:block xs:text-center lg:text-left lg:flex">
                  <i className="icon-Envelop lg:block text-center self-center text-xl lg:w-1/12 mr-2 xs:relative xs:top-1 lg:top-0" data-in-viewport="scale-in"></i>
                  <span className="text-sm lg:w-11/12">{contactInfo?.email}</span>
                </a>
              </li>
              <li>
                <span className="xs:block xs:text-center lg:text-left lg:flex">
                  <i className="icon-Location lg:block text-center self-center text-xl lg:w-1/12 mr-2 xs:relative xs:top-1 lg:top-0" data-in-viewport="scale-in"></i>
                  <span className="text-sm lg:w-11/12">{contactInfo?.addressPlace}, {contactInfo?.addressLocation}</span>
                </span>
              </li>
              <li>
                <a href="tel:+59825182415" className="xs:block xs:text-center lg:text-left lg:flex">
                  <i className="icon-Phone lg:block text-center self-center text-xl lg:w-1/12 mr-2 xs:relative xs:top-1 lg:top-0" data-in-viewport="scale-in"></i>
                  <span className="text-sm lg:w-11/12">{contactInfo?.phoneNumber}</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="xs:w-full lg:w-1/3 xs:py-8 xs:px-4 lg:px-8 md:pr-0">
            <ul>
              <li
                className="flex items-center mb-4 xs:text-sm xs:justify-center lg:text-md lg:justify-start"
                dangerouslySetInnerHTML={{ __html: content?.navigation?.downloadLinks?.[0] }}
                />
              <li
                className="flex items-center mb-4 xs:text-sm xs:justify-center lg:text-md lg:justify-start"
                dangerouslySetInnerHTML={{ __html: content?.navigation?.downloadLinks?.[1] }}
              />
            </ul>
          </div>
        </div>
        <p className="text-xs mt-8 xs:text-center md:text-right">
          Sadmer Corporation SA - Todos los derechos reservados 2023 | Nelcord Financial Services | Creado por iCom
        </p>
      </div>
    </footer>
  );
}

export default Footer;
