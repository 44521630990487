import videoBackground from './assets/media/bgplayback-v4.3.mp4';
import { getAuth, signOut } from 'firebase/auth';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Header from './components/Header/Header';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import AdminTools from './components/AdminBar/AdminTools';
import Footer from './components/Footer/Footer';

import AuthLogin from './views/AuthLogin';
import Loading from './views/Loading';
import NotFound from './views/NotFound';
import Home from './views/Home';
import WealthManagement from './views/WealthManagement';
import InsuranceServices from './views/InsuranceServices';
import Benefits from './views/Benefits';

import useLanguage from './hooks/useLanguage';
import TranslationService from './services/TranslationService';
import LanguageService from './services/LanguageService';

const App = () => {
  const [language, changeLanguage] = useLanguage();
  const [translations, setTranslations] = useState({});
  const [translationState, setTranslationState] = useState({
    loading: true,
    error: false,
    data: null,
  });
  const [languagesState, setLanguagesState] = useState({
    loading: true,
    error: false,
    list: null,
  });
  const [openPanel, setOpenPanel] = useState(false);

  const handleLanguages = useCallback((gotLanguages) => {
    const languages = {
      ...languagesState,
      ...{
        loading: false,
        list: gotLanguages,
      }
    };
    setLanguagesState(languages);
  }, [languagesState, setLanguagesState]);

  const handleLanguagesError = useCallback((error) => {
    const languages = {
      ...languagesState,
      ...{
        loading: false,
        error: true,
      }
    };
    setLanguagesState(languages);
  }, [languagesState, setLanguagesState]);

  const translate = useCallback(
    (translation) => setTranslations(translation.find(t => t.id === language)),
    [language]
  );

  const handleLanguageChange = useCallback((language) => {
    const languageSelected = languagesState?.list?.find((item) => item.label === language);
    changeLanguage(languageSelected.code)
  }, [changeLanguage, languagesState]);

  const handleTranslations = useCallback(gotTranslations => {
    const translations = {
      ...translationState,
      ...{
        loading: false,
        data: gotTranslations,
      }
    };

    setTranslationState(translations);
  }, [translationState, setTranslationState]);

  const handleTranslationsError = useCallback(error => {
    const translations = {
      ...translationState,
      ...{
        loading: false,
        error: true,
        data: error
      }
    };
    setTranslationState(translations);
  }, [translationState, setTranslationState]);

  useEffect(() => {
    if (!languagesState.loading) return;
    LanguageService
      .getAll()
      .then(handleLanguages)
      .catch(handleLanguagesError);
  }, [languagesState, handleLanguages, handleLanguagesError]);

  useEffect(() => {
    if (translationState.loading) return;
    translate(translationState.data)
  }, [translationState, translate]);

  useEffect(() => {
    if (!translationState.loading) return;
    TranslationService
      .getAll()
      .then(handleTranslations)
      .catch(handleTranslationsError);
  }, [translationState, handleTranslations, handleTranslationsError]);

  useLayoutEffect(() => {
    const backgroundElements = document.querySelectorAll('[background]');
    const backgroundCounts = backgroundElements.length;

    if (backgroundCounts) {
      const backgroundElementsParent = backgroundElements[0].parentNode;
      const backgroundWrapper = document.createElement('div');
      const wrapperAttribute = document.createAttribute('background-wrapper');

      backgroundWrapper.setAttributeNode(wrapperAttribute);
      backgroundWrapper.append(...backgroundElements);

      backgroundElementsParent.innerHTML = "";
      backgroundElementsParent.append(backgroundWrapper);

      setTimeout(switchbackgrounds, 100);

      if (backgroundCounts > 1) {
        setInterval(switchbackgrounds, 5000);
      }

      function switchbackgrounds() {
        const activebackground = backgroundWrapper.querySelector('.active-in');
        const nextbackground = activebackground?.nextSibling || backgroundWrapper.querySelector('[background]:first-child');
        activebackground?.classList.add('active-out');
        activebackground?.classList.remove('active-in');
        nextbackground?.classList.add('active-in');

        setTimeout(() => {
          activebackground?.classList.remove('active-out');
        }, 1000);
      }
    }
  }, []);

  return (
    <Router className="App">
      <Header languages={languagesState} language={language} onLanguageChange={handleLanguageChange} />

      <main>
        <ScrollToTop>
          <AdminTools onChange={() => {
              setOpenPanel(!openPanel);
              setTranslationState({
                ...translationState,
                loading: true,
              });
            }}
            loading={translationState.loading}
            openPanel={openPanel}
            allContent={translationState.data}
            languages={languagesState.list ?? []}
            language={language || "es"}
          >
            <Switch>
              <Route exact path="/admin-login">
                <AuthLogin />
              </Route>
              <Route exact path="/admin-logout" render={() => {
                signOut(getAuth());
                return <Redirect to="/" />;
              }} />
              {translationState.loading && <Loading />}
              {
                !translationState.loading &&
                <Switch>
                  <Route exact path="/">
                    <Home buttons={translations?.buttons} blocks={translations?.blocks} content={translations?.pages?.Home} />
                  </Route>
                  <Route exact path="/wealth-management">
                    <WealthManagement buttons={translations?.buttons} blocks={translations?.blocks} content={translations?.pages?.Wealth} />
                  </Route>
                  <Route exact path="/insurance-services">
                    <InsuranceServices buttons={translations?.buttons} blocks={translations?.blocks} content={translations?.pages?.Insurance} />
                  </Route>
                  <Route exact path="/benefits">
                    <Benefits buttons={translations?.buttons} blocks={translations?.blocks} content={translations?.pages?.Benefits} />
                  </Route>
                  <Route exact path="*">
                    <NotFound buttons={translations?.buttons} content={translations?.blocks?.Page404} contact={{ info: translations?.blocks?.ContactInfo, form: translations?.blocks?.ContactForm }} />
                  </Route>
                </Switch>
              }
            </Switch>
          </AdminTools>
        </ScrollToTop>
      </main>

      <Footer content={translations?.blocks?.Footer} contactInfo={translations?.blocks?.ContactInfo} />

      <div className="fixed inset-0 w-screen h-screen object-cover bgplayback-mobile">
        <div className="fixed inset-0 w-screen h-screen xs:block lg:hidden capture-5">
          <div className="w-screen h-screen bgplayback-mobile capture-1" background="true"></div>
          <div className="w-screen h-screen bgplayback-mobile capture-2" background="true"></div>
          <div className="w-screen h-screen bgplayback-mobile capture-3" background="true"></div>
          <div className="w-screen h-screen bgplayback-mobile capture-4" background="true"></div>
          <div className="w-screen h-screen bgplayback-mobile capture-5" background="true"></div>
        </div>
        <video autoPlay muted loop className="w-screen h-screen object-cover xs:hidden lg:block">
          <source src={videoBackground} type="video/mp4" className="xs:hidden xl:block" />
        </video>
      </div>
    </Router>
  );
}

export default App;
