import { Fragment, useLayoutEffect, useState, useCallback } from "react";
import Contact from "../components/Contact/Contact";
import Section from "../components/Section/Section";
import NotFound from "./NotFound";

const Benefits = ({ blocks, content }) => {
  const [contactInfo, setContactInfo] = useState({
    fullname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleFormSubject = useCallback((e) => {
    const fieldName = e.target.getAttribute('sendto');
    const fieldValue = e.target.getAttribute('sendvalue');

    setContactInfo({
      ...contactInfo,
      [fieldName]: fieldValue,
    });
  }, [contactInfo]);

  useLayoutEffect(() => {
    const inViewport = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("inViewport", entry.isIntersecting);
        }
      });
    };

    const Obs = new IntersectionObserver(inViewport);
    const obsOptions = {
      threshold: 1
    };

    const elementsInViewport = document.querySelectorAll('[data-in-viewport]');

    elementsInViewport.forEach(element => {
      Obs.observe(element, obsOptions);
    });

    const callToActions = document.querySelectorAll('[sendto]');
    callToActions.forEach(callToAction => {
      callToAction.addEventListener("click", handleFormSubject);
    });
  }, [content, handleFormSubject]);

  return (
    <>
      {!content && <NotFound content={blocks?.Page404} contact={{ info: blocks?.ContactInfo, form: blocks?.ContactForm }}/>}
      {content && (
        <>
          {(
            content?.Hero?.title
            || content?.Hero?.description
          ) && (
            <section id={content?.idRef} className="benefits relative z-10 bg-semi-transparent">
              <div className={[
                "min-w-full",
                "min-h-screen",
                "p-8",
                "pt-32",
                "flex",
                "flex-col",
                "items-center",
                "justify-center",
                "text-white",
              ].join(" ")}
                style={{
                  backgroundImage: "url(/assets/images/chill-meeting.jpg)",
                  backgroundSize: "cover"
                }}>
                {content.Hero.title && (
                  <h1
                    className="benefits-title relative uppercase text-4xl"
                    dangerouslySetInnerHTML={{ __html: content.Hero.title }}
                  />
                )}
                {content.Hero.description && (
                  <p
                    style={{
                      width: "50%",
                      marginLeft: "51%",
                      paddingRight: "25%",
                      fontWeight: "lighter",
                      padding: "1rem 25% 1rem 0",
                      lineHeight: "2rem",
                    }}
                    dangerouslySetInnerHTML={{ __html: content.Hero.description }}
                  />
                )}
              </div>
            </section>
          )}

          <Section id={content?.Body?.idRef}
            background="bg-white"
            textColor="text-gray-600"
            className="benefits-hero"
          >
            <div className="leading-10">
              {content.Body.content.map((item, index) => (
                <Fragment key={index}>
                  {(
                    item.type === "paragraph"
                    && item.value
                  ) && (
                    <p key={index} className={[
                      "mb-4",
                      index === 2 && "float-left",
                      index === 3 && "clear-left",
                    ].join(" ")} data-in-viewport="scale-in" dangerouslySetInnerHTML={{ __html: item.value }}>
                    </p>
                  )}
                  {(
                    item.type === "list"
                    && item.values.length
                  ) && (
                    <ul className="list-disc ml-8 mb-4">
                      {item.values.map((listItem, listIndex) => (
                        <li key={listIndex} className="list-item" data-in-viewport="scale-in">{listItem}</li>
                      ))}
                    </ul>
                  )}
                </Fragment>
              ))}
            </div>
          </Section>
        </>
      )}
      <Contact contactInfoTranslation={blocks?.ContactInfo} contactFormTranslation={blocks?.ContactForm} pushContactInfo={contactInfo} />
    </>
  )
}

export default Benefits;
