import Contact from '../components/Contact/Contact';

const NotFound = ({ content, contact }) => {
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: content?.content }} />
      <Contact contactInfoTranslation={contact?.info} contactFormTranslation={contact?.form} />
    </>
  );
};

export default NotFound;
