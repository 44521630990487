import { useCallback, useEffect, useRef, useState } from "react";
import "./SelectList.css";

const SelectList = ({
  value,
  options,
  onChange,
  onSelect,
  className,
  listClassName,
  listItemClassName,
  iconSettings,
  ...otherProps
}) => {
  const [listVisible, setListVisible] = useState(false);
  const [fireBlur, setFireBlur] = useState(true);
  const [fireClick, setFireClick] = useState(true);
  const [inputValue, setInputValue] = useState(value);

  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(value);
  }, [value])
  
  const handleSelectClick = useCallback(() => {
    if (!fireClick) return;
    if (!listVisible) {
      setListVisible(true);
      inputRef.current.focus();
      inputRef.current.select();
    } else {
      setListVisible(false);
    }
  }, [fireClick, listVisible, setListVisible]);

  const handleOverList = useCallback(() => {
    setFireBlur(!fireBlur);
  }, [fireBlur, setFireBlur]);

  const handleInputBlur = useCallback(() => {
    if (!fireBlur) return;
    setFireClick(false);
    setListVisible(false);
    setTimeout(() => setFireClick(true), 100);
  }, [fireBlur, setFireClick, setListVisible]);

  const handleOptionClick = useCallback((e) => {
    e.preventDefault();
    setListVisible(false);
    setInputValue(e.target.value);
    onSelect && onSelect(e.target.value);
  }, [setListVisible, onSelect]);

  return (
    <div className={
      [
        "select-list",
        "relative",
        iconSettings && [
          iconSettings?.size ? `size${iconSettings?.size}` : "",
          iconSettings?.top ? `top${iconSettings?.top}` : "",
          iconSettings?.right ? `right${iconSettings?.right}` : "",
          iconSettings?.bottom ? `bottom${iconSettings?.bottom}` : "",
          iconSettings?.left ? `left${iconSettings?.left}` : "",
        ].join(" "),
        listVisible ? "select-focus" : "",
      ].join(" ")}
      onClick={handleSelectClick}
    >
      <input className={[
        className ?? "",
      ].join(" ")}
        ref={inputRef}
        value={inputValue ?? ""}
        onBlur={handleInputBlur}
        onChange={onChange}
        {...otherProps}
      />
      <ul id="subjects"
        className={[
          listVisible ? "block" : "hidden",
          listClassName ?? "",
        ].join(" ")}
        onMouseOver={handleOverList}
        onMouseOut={handleOverList}
      >
        {options?.map(opt => (
          <li key={opt.key} className="w-full">
            <button className={[
              "w-full",
              listItemClassName ?? ""
            ].join(" ")}
              value={opt.value}
              onClick={handleOptionClick}
            >{opt.title}</button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SelectList;
