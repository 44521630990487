import store from "./firebase";

const db = store.collection("/language");

const getAll = async () => {
  const pages = await db.get();
  return await pages.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

const create = (data) => {
  return db.add(data);
};

const update = (id, value) => {
  return db.doc(id).update(value);
};

const remove = (id) => {
  return db.doc(id).delete();
};

const PageService = {
  getAll,
  create,
  update,
  remove
};

export default PageService;
