import { Link } from "react-router-dom";

const AdminLink = ({ url, action, className, children }) => {
  const classes = [
    "inline-block",
    "text-center",
    "py-4",
    "px-2",
    className ?? "",
  ];

  const linkProps = {
    ...(url ? {to: url} : {}),
    ...(action ? {onClick: action} : {}),
    className: classes.join(" "),
  };

  return (
    <>
      {url && <Link {...linkProps}>{children}</Link>}
      {!url && <button {...linkProps}>{children}</button>}
    </>
  );
};

export default AdminLink;