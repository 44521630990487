import { forwardRef, useCallback, useEffect, useState } from "react";
import AdminInput from "./Controllers/AdminInput";

const FooterEditForm = forwardRef(({ data, onSaveChanges }, ref) => {
  const [footerInfo, setFooterInfo] = useState(data);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback((ev) => {
    ev.preventDefault();
    setSaving(true);
  }, [setSaving]);

  useEffect(() => {
    if (saving) {
      onSaveChanges(footerInfo);
      setSaving(false);
    }
  }, [saving]);

  useEffect(() => {
    setFooterInfo(data);
  }, [data]);

  return (
    <form ref={ref} className="p-4" onSubmit={handleSave}>
      <fieldset className="px-2 pb-4 border-2">
        <legend className="p-2 text-2xl">Pie de página</legend>
        <div className="p-2">
          <h2 className="text-xl mb-4">Nelcord: Marca Comercial</h2>
          <AdminInput isSaving={saving} onChange={setFooterInfo} type="longtext" label="Texto" source={footerInfo} path="tradeMark" value={footerInfo?.tradeMark || ""} />
        </div>

        <div className="p-2">
          <h2 className="text-xl mb-4">Navegación</h2>
          <AdminInput isSaving={saving} onChange={setFooterInfo} type="text" label="Título" source={footerInfo} path="navigation.title" value={footerInfo?.navigation?.title || ""} />

          <h2 className="text-xl mb-4">Links</h2>
          <AdminInput isSaving={saving} onChange={setFooterInfo} type="text" label="Link" source={footerInfo} path="navigation.links.0" value={footerInfo?.navigation?.links?.[0] || ""} />
          <AdminInput isSaving={saving} onChange={setFooterInfo} type="text" label="Link" source={footerInfo} path="navigation.links.1" value={footerInfo?.navigation?.links?.[1] || ""} />
          <AdminInput isSaving={saving} onChange={setFooterInfo} type="text" label="Link" source={footerInfo} path="navigation.links.2" value={footerInfo?.navigation?.links?.[2] || ""} />
          <AdminInput isSaving={saving} onChange={setFooterInfo} type="text" label="Link" source={footerInfo} path="navigation.links.3" value={footerInfo?.navigation?.links?.[3] || ""} />

          <h2 className="text-xl mb-4">Links de descarga</h2>
          <AdminInput isSaving={saving} onChange={setFooterInfo} type="longtext" label="Link de descarga" source={footerInfo} path="navigation.downloadLinks.0" value={footerInfo?.navigation?.downloadLinks?.[0] || ""} />
          <AdminInput isSaving={saving} onChange={setFooterInfo} type="longtext" label="Link de descarga" source={footerInfo} path="navigation.downloadLinks.1" value={footerInfo?.navigation?.downloadLinks?.[1] || ""} />
        </div>
      </fieldset>
    </form>
  );
});

export default FooterEditForm;
