import { useState } from "react";

const getLanguage = () => {
  const language = localStorage.getItem("language");
  if (language) {
    return language;
  }
  return navigator.language.split("-")[0];
}

const useLanguage = () => {
  const userLanguage = getLanguage();
  const [language, setLanguage] = useState(userLanguage);

  const changeLanguage = (lang) => {
    localStorage.setItem("language", lang);
    setLanguage(lang);
  };

  return [language, changeLanguage];
}

export default useLanguage;