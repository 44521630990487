import { Fragment, useCallback, useLayoutEffect, useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import Contact from "../components/Contact/Contact";
import Section from "../components/Section/Section";
import useStringUtils from "../hooks/useStringUtils";
import NotFound from "./NotFound";

const InsuranceServices = ({ buttons, blocks, content }) => {
  const { toUpperCammelCase } = useStringUtils();
  const [contactInfo, setContactInfo] = useState({
    fullname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  useLayoutEffect(() => {
    const inViewport = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("inViewport", entry.isIntersecting);
        }
      });
    };

    const Obs = new IntersectionObserver(inViewport);
    const obsOptions = {
      threshold: 1
    };

    const elementsInViewport = document.querySelectorAll('[data-in-viewport]');

    elementsInViewport.forEach(element => {
      Obs.observe(element, obsOptions);
    });
  }, [content]);

  const handleFormSubject = useCallback((e) => {
    const fieldName = e.target.getAttribute('sendto');
    const fieldValue = e.target.getAttribute('sendvalue');

    setContactInfo({
      ...contactInfo,
      [fieldName]: fieldValue,
    });
  }, [contactInfo]);

  return (
    <>
      {!content && <NotFound content={blocks?.Page404} contact={{ info: blocks?.ContactInfo, form: blocks?.ContactForm }}/>}
      {content && (
        <>
          {(
            content?.Hero?.title
            || content?.Hero?.description
          ) && (
            <Section id={content?.idRef}
              background="bg-chroma-red"
              textColor="text-white"
              className="pt-32"
            >
              {content.Hero.title && (
                <h1
                  className="xs:text-3xl lg:text-5xl xs:text-center lg:text-left font-bold mb-8"
                  data-in-viewport="scale-in"
                  dangerouslySetInnerHTML={{ __html: content.Hero.title }}
                />
              )}

              {content.Hero.description && (
                <div className="flex xs:flex-col lg:flex-row xs:mb-8 lg:mb-0">
                  <i className="xs:block lg:hidden icon-Nelcord text-10xl xs:w-full lg:w-2/5 xs:text-center xs:mb-8 lg:mb-0 lg:pr-16 xs:text-family-background" data-in-viewport="scale-in"></i>
                  <i className="xs:hidden lg:block icon-Nelcord text-xxl xs:w-full lg:w-2/5 xs:text-center xs:mb-8 lg:mb-0 lg:pr-16 text-family-background" data-in-viewport="scale-in"></i>

                  <div className="text-base leading-8 text-white xs:w-full lg:w-3/5 pb-12 self-center border-b">
                    <p
                      className="mb-8"
                      data-in-viewport="scale-in"
                      dangerouslySetInnerHTML={{ __html: content.Hero.description }}
                    />
                  </div>
                </div>
              )}

              {content.Hero.content.map((item, index) => (
                <div key={index} className={[
                  "xs:mb-8",
                  "xs:pb-8",
                  "lg:mb-0",
                  "lg:pb-0",
                  content.Hero.content.length -1 !== index ? "border-b" : "",
                ].join(" ")}>
                  <div className="flex xs:flex-col lg:flex-row pt-8 pb-4">
                    {item.class && (
                      <h2 className="uppercase font-bold xs:text-4xl sm:text-5xl xs:w-full lg:w-2/5 xs:mb-2 sm:mb-1 lg:pr-16 self-center flex items-center" data-in-viewport="scale-in">
                        Nelcord <i className={[
                          "font-normal",
                          "ml-2",
                          item.class
                        ].join(" ")}></i>
                      </h2>
                    )}
                    {item.content.length && (
                      <div className="text-base leading-8 text-white xs:w-full lg:w-3/5 xs:pb-8 lg:py-4 self-center" data-in-viewport="scale-in">
                        {item.content.map((paragraph, index) => (
                          <p
                            key={index}
                            className="mb-4"
                            dangerouslySetInnerHTML={{ __html: paragraph.value }}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                  <div className={[
                    "xs:block",
                    "text-right",
                    content.Hero.content.length -1 !== index ? "lg:pb-12" : "",
                  ].join(" ")}>
                    <NavHashLink to={`/insurance-services#${item.title.toLowerCase().split(' ').join('-')}`}
                      className="py-2 px-8 uppercase border rounded-full xs:block xs:w-full md:inline md:w-auto text-center xs:mb-4 md:mr-4 md:mb-0"
                      data-in-viewport="scale-in"
                      dangerouslySetInnerHTML={ { __html: buttons?.MoreInfo?.text } }
                    />
                  </div>
                </div>
              ))}
            </Section>
          )}

          {content.Body.content.map((item, index) => (
            <Section id={item?.idRef}
              background={[
                index % 2 === 0 ? "bg-gray-100" : "bg-white",
              ].join(" ")}
              textColor="text-gray-600"
            >
              {item.class && (
                <h2 className="uppercase font-bold xs:text-4xl sm:text-5xl xs:w-full self-center flex items-center" data-in-viewport="scale-in">
                  Nelcord <i className={[
                    "font-normal",
                    "ml-2",
                    item.class
                  ].join(" ")}></i>
                </h2>
              )}

              {item.title && (
                <h3
                  className="uppercase font-bold xs:text-2xl sm:text-3xl xs:w-full lg:pb-8"
                  data-in-viewport="scale-in"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
              )}

              {item.content.map((item, index) => (
                <Fragment key={index}>
                  {(
                    item.type === "paragraph"
                    && item.value
                  ) && (
                    <p
                      key={index}
                      className="mb-8"
                      data-in-viewport="scale-in"
                      dangerouslySetInnerHTML={{ __html: item.value }}
                    />
                  )}
                  {(
                    item.type === "list"
                    && item.values.length
                    && item.values?.[0]
                  ) && (
                    <ol className="list-disc ml-8 mb-4">
                      {item.values.map((listItem, listIndex) => (
                        listItem && <li
                          key={listIndex}
                          className="list-item"
                          data-in-viewport="scale-in"
                          dangerouslySetInnerHTML={{ __html: listItem }}
                        />
                      ))}
                    </ol>
                  )}
                </Fragment>
              ))}
              <div className="xs:block md:float-right" data-in-viewport="scale-in">
                <NavHashLink to="#contact-form"
                  onClick={handleFormSubject}
                  sendto="subject"
                  sendvalue={toUpperCammelCase(item.idRef.split("-").join(" "))}
                  className={[
                    "py-2 px-8",
                    "uppercase",
                    "border",
                    "border-gray-600",
                    "rounded-full",
                    "xs:w-full",
                    "md:w-auto",
                    "float-right",
                    "flex",
                    "justify-center",
                    "items-center",
                  ].join(" ")}
                  data-in-viewport="scale-in"
                  dangerouslySetInnerHTML={ { __html: buttons?.RequestAdvisor?.text } }
                />
              </div>
            </Section>
          ))}
        </>
      )}

      <Contact contactInfoTranslation={blocks?.ContactInfo} contactFormTranslation={blocks?.ContactForm} pushContactInfo={contactInfo} />
    </>
  );
};

export default InsuranceServices;
