import firebase from "firebase/compat/app";
import store from "./firebase";

const db = store.collection("/translation-test");

const getAll = async () => {
  const translations = await db.get();
  return await translations.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

const getBy = async (options) => {
  const field = options.field === "id" ? firebase.firestore.FieldPath.documentId() : options.field;
  const translations = await db.where(field, "==", options.value).get();
  return await translations.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

const create = (data) => {
  return db.doc(data.id).set(data);
};

const update = (id, value) => {
  return db.doc(id).update(value);
};

const remove = (id) => {
  return db.doc(id).delete();
};

const TranslationService = {
  getAll,
  getBy,
  create,
  update,
  remove
};

export default TranslationService;
