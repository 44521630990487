import { useEffect, useState } from "react";
import { Redirect } from "react-router";
import Input from "../components/FormControl/Controls/Input";

import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

const AuthLogin = () => {
  const [userCredentials, setUserCredentials] = useState({});
  const [loadingSignIn, setLoadingSignIn] = useState(true);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginError, setLoginError] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user && !loginSuccess) {
        setLoginSuccess(true);
      }
      setLoadingSignIn(false);
    });
    return () => {
      unsubscribe();
    }
  }, [loginSuccess])

  const handleInputChange = (ev) => {
    setUserCredentials({
      ...userCredentials,
      ...{
        [ev.target.name]: ev.target.value
      }
    });
  };

  const handleLoginSubmit = (ev) => {
    ev.preventDefault();
    signInWithEmailAndPassword(getAuth(), userCredentials.email, userCredentials.password)
      .then(() => setLoginSuccess(true))
      .catch(setLoginError);
  }

  const isEmptyField = (target, field) => {
    return !!(target[field] != null && target[field].trim() === "");
  };

  return (
    <>
      {loginSuccess && <Redirect to="/" />}
      <section id="admin-login" className={
        [
          "admin-login",
          "relative",
          "z-10",
          "bg-white",
          "pt-32",
          loadingSignIn && "opacity-50",
        ].join(' ')
      }>
        <div className={[
            "lg:container",
            "mx-auto",
            "xs:p-8",
            "xl:p-16",
            "xl:flex",
            "xs:flex",
            "xs:flex-col",
          ].join(' ')}
        >
          <form onSubmit={handleLoginSubmit} className={[
            "m-auto",
            "xs:w-full",
            "lg:w-1/3",
            "space-y-4",
            "p-8",
            "text-lg",
            "border-2",
            "rounded-lg",
            "shadow-lg",
          ].join(" ")}>
            {loginError && <p className="p-4 bg-red-300 text-sm text-red-800 text-center rounded-lg">Hubo un error! Intenta de nuevo...</p>}
            <Input
              id="email"
              label="E-mail"
              value={userCredentials?.email}
              onChange={handleInputChange}
              error={isEmptyField(userCredentials, "email") && {
                message: "El correo no puede ser vacío"
              }}
            />
            <Input
              id="password"
              type="password"
              label="Contraseña"
              value={userCredentials?.password}
              onChange={handleInputChange}
              error={isEmptyField(userCredentials, "password") && {
                message: "Introduce una contraseña válida"
              }}
            />
            <button type="submit"
              className={[
                "py-1",
                "px-4",
                "uppercase",
                "text-gray-600",
                "border-gray-600",
                "border",
                "rounded-full",
                "w-full",
                (!userCredentials["email"]
                  && !userCredentials["password"])
                  || (isEmptyField(userCredentials, "email")
                    || isEmptyField(userCredentials, "password"))
                    ? "opacity-50"
                    : "",
                (!userCredentials["email"]
                  && !userCredentials["password"])
                  || (isEmptyField(userCredentials, "email")
                    || isEmptyField(userCredentials, "password"))
                    ? "cursor-not-allowed"
                    : ""
              ].join(" ")}
              disabled={(!userCredentials["email"]
                && !userCredentials["password"])
                || (isEmptyField(userCredentials, "email")
                  || isEmptyField(userCredentials, "password"))}
            >
              Entrar
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default AuthLogin;
