import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './services/firebase';
// import { initializeApp } from '@firebase/app';

// const config = {
//   apiKey: 'AIzaSyC4CseAU5SBpikLsyUQsGUaFEijYuKt1A0',
//   projectId: 'nelcord-api',
//   authDomain: 'nelcord-api.firebaseapp.com',
// };
// initializeApp(config);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
