const FormActions = ({ form, onSubmit, onCancel, saving }) => {
  return (
    <>
      <button
        type="button"
        className={[
          "px-4",
          "py-2",
          "bg-green-300",
          saving ? "opacity-50 cursor-not-allowed" : "cursor-pointer",
        ].join(" ")}
        onClick={onSubmit}
        disabled={saving}
      >
        {saving
          ? <i class="fas fa-spinner"></i>
          : <i class="far fa-save"></i>
        }
      </button>
      <button
        type="reset"
        className={[
          "px-4",
          "py-2",
          "bg-red-300",
          saving ? "opacity-50 cursor-not-allowed" : "cursor-pointer",
        ].join(" ")}
        onClick={onCancel}
        disabled={saving}
      >
        <i class="fas fa-times"></i>
      </button>
    </>
  );
};

export default FormActions;
