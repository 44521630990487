import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import objectPath from "object-path";
import FormEditBlock from './FormEditBlock';
import FormEditPage from './FormEditPage';

import TranslationService from '../../services/TranslationService';
import FormEditButton from './FormEditButton';
import FormActions from './Forms/Controllers/FormActions';
// import FormModal from './FormModal/FormModal';

const PAGES = 'pages';
const BLOCKS = 'blocks';
const BUTTONS = 'buttons';
const NEW = 'new';
const UPDATE = 'update';

const cleanObjectProps = (obj) => {
  let objCopy;
  if (!obj) return {};
  if (obj.length) {
    objCopy = [ ...obj ];
  } else {
    objCopy = { ...obj };
  }
  Object.keys(objCopy).forEach(key => {
    if (typeof objCopy[key] === 'object' && objCopy[key] !== null) {
      objCopy[key] = cleanObjectProps(objCopy[key]);
    } else if (key !== 'type') {
      objCopy[key] = null;
    }
  });
  return objCopy;
};

const AdminPanel = ({ allContent, onChange, languages, language }) => {
  const [onSaveAction, setOnSaveAction] = useState(null);
  const [editContent, setEditContent] = useState(allContent.find(content => content.id === language) || { id: language, blocks: {}, pages: {} });
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [editLanguage, setEditLanguage] = useState(language);
  const [editGroup, setEditGroup] = useState(null);
  const [editPage, setEditPage] = useState(null);
  const [editBlock, setEditBlock] = useState(null);
  const [editButton, setEditButton] = useState(null);
  const [saving, setSaving] = useState(false);
  const formRef = useRef(null);

  const handleLanguage = useCallback((code) => setEditLanguage(code), []);

  // const newLanguage = useCallback(() => setEditLanguage(code), []);

  // const handleModalSubmit = useCallback((event) => {
  //   console.log(event);
  // }, []);

  const setGroup = useCallback(group => {
    switch(group) {
      case PAGES:
        setEditGroup(editContent?.pages || {});
        break;
      case BLOCKS:
        setEditGroup(editContent?.blocks || {});
        break;
      case BUTTONS:
        setEditGroup(editContent?.buttons || {});
        break;
      default:
        setEditGroup(null);
        break;
    }
  }, [editContent]);

  const handleSelectGroup = useCallback((group) => {
    if (group === selectedGroup) return;
    setSelectedGroup(group);
    setEditPage(null);
    setEditBlock(null);
    setEditButton(null);
    setGroup(group);
  }, [selectedGroup, setGroup]);

  const handleSave = useCallback((group, data) => {
    setSaving(true);
    if (onSaveAction === UPDATE) {
      TranslationService.getBy({
        field: 'id',
        value: editLanguage
      })
        .then(translation => {
          const updatedTranslation = { ...translation[0] };
          objectPath.set(updatedTranslation, `${group}.${data.id}`, data);
          return TranslationService.update(editLanguage, updatedTranslation);
        })
        .then(res => {
          onChange();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setSaving(false);
        });
    }

    if (onSaveAction === NEW) {
      const newContent = {
        ...editContent,
        [group]: {
          ...editContent[group],
          [data.id]: data
        },
      };
      TranslationService
        .create(newContent)
        .then(res => {
          onChange();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  }, [editLanguage, editContent, onSaveAction, onChange]);

  const handleAltSave = useCallback((ev) => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  }, []);

  const handleCancel = useCallback((ev) => {
    formRef.current.dispatchEvent(
      new Event("reset", { cancelable: true, bubbles: true })
    );
  }, []);

  useLayoutEffect(() => {
    let newContent = allContent.find(content => content.id === editLanguage);

    if (!newContent) {
      newContent = cleanObjectProps(editContent);
      newContent.id = editLanguage;
      setOnSaveAction(NEW);
    } else {
      setOnSaveAction(UPDATE);
    }

    if (editLanguage === editContent.id) return;

    setEditContent(newContent);
    setSelectedGroup(selectedGroup);
    setEditPage(null);
    setEditBlock(null);
    setEditButton(null);

    switch(selectedGroup) {
      case PAGES:
        setEditGroup(newContent?.pages || {});
        const newPage = newContent?.pages?.[editPage?.id] || {};
        newPage.id = editPage?.id;
        setEditPage(newPage);
        break;
      case BLOCKS:
        setEditGroup(newContent?.blocks || {});
        const newBlock = newContent?.blocks?.[editBlock?.id] || {};
        newBlock.id = editBlock?.id;
        setEditBlock(newBlock);
        break;
      case BUTTONS:
        setEditGroup(newContent?.buttons || {});
        const newButton = newContent?.buttons?.[editButton?.id] || {};
        newButton.id = editButton?.id;
        setEditButton(newButton);
        break;
      default:
        setEditGroup(null);
        break;
    }

  }, [editLanguage, allContent, selectedGroup, editGroup, editContent, editPage, editBlock, editButton, setGroup]);

  return (
    <section className={[
      'fixed',
      'top-0',
      'left-0',
      'bg-white',
      'w-full',
      'h-full',
      'z-50',
      'flex',
      'flex-col'
    ].join(' ')}>
      <header className={[
        'relative',
        'z-10',
        'p-4',
        'text-center',
        'shadow-md'
      ].join(' ')}>
        <h1 className='text-xl font-bold'>Admin panel</h1>
      </header>

      <main className='flex flex-row flex-grow overflow-hidden'>
        {/* Páginas - Bloques - Botones */}
        <aside className={[
          'relative',
          'z-9',
          'shadow-md',
          'w-1/12',
          'p-2',
        ].join(' ')}>
          <ul className='list-reset'>
            <li>
              <button
                className={[
                  'p-2',
                  'border-1',
                  (selectedGroup === PAGES) ? 'bg-blue-500 text-white' : 'bg-gray-200',
                ].join(' ')}
                onClick={() => handleSelectGroup(PAGES)}
              >
                <i className="material-icons mr-1 inline-block font-bold">backup_table</i>
                <span className="inline-block">Páginas</span>
              </button>
            </li>
            <li>
              <button
                className={[
                  'p-2',
                  'border-1',
                  (selectedGroup === BLOCKS) ? 'bg-blue-500 text-white' : 'bg-gray-200',
                ].join(' ')}
                onClick={() => handleSelectGroup(BLOCKS)}
              >
                <i className="material-icons mr-1 inline-block font-bold">grid_view</i>
                <span className="inline-block">Bloques</span>
              </button>
            </li>
            <li>
              <button
                className={[
                  'p-2',
                  'border-1',
                  (selectedGroup === BUTTONS) ? 'bg-blue-500 text-white' : 'bg-gray-200',
                ].join(' ')}
                onClick={() => handleSelectGroup(BUTTONS)}
              >
                <i className="material-icons mr-1 inline-block font-bold">call_to_action</i>
                <span className="inline-block">Botones</span>
              </button>
            </li>
          </ul>
        </aside>

        {/* Links a Páginas - Links a Bloques - Links a Botones */}
        <aside className={[
          'relative',
          'z-9',
          'shadow-md',
          'w-3/12',
          'p-2',
        ].join(' ')}>
          {!selectedGroup &&
            <h2 className={[
              'mt-4',
              'text-center',
              'text-2xl',
              'font-bold',
              'text-gray-400'
            ].join(' ')}>Comienza a editar tus páginas, bloques y botones</h2>
          }
          {selectedGroup === PAGES && (
            <ul>
              <li>
                <button
                  className={[
                    'block',
                    'w-full',
                    'text-left',
                    'p-2',
                    'flex',
                    (editPage?.id === 'Home') ? 'bg-blue-500 text-white' : 'bg-gray-200',
                  ].join(' ')}
                  onClick={() => {
                    setEditGroup(editContent?.pages || {});
                    setEditPage(editGroup?.Home || { id: 'Home' });
                  }}
                >
                  <i className="material-icons mr-1 inline-block font-bold">description</i>
                  Home
                </button>
              </li>
              <li>
                <button
                  className={[
                    'block',
                    'w-full',
                    'text-left',
                    'p-2',
                    'flex',
                    (editPage?.id === 'Wealth') ? 'bg-blue-500 text-white' : 'bg-gray-200',
                  ].join(' ')}
                  onClick={() => {
                    setEditGroup(editContent?.pages || {});
                    setEditPage(editGroup?.Wealth || { id: 'Wealth' });
                  }}
                >
                  <i className="material-icons mr-1 inline-block font-bold">description</i>
                  Wealth Management
                </button>
              </li>
              <li>
                <button
                  className={[
                    'block',
                    'w-full',
                    'text-left',
                    'p-2',
                    'flex',
                    (editPage?.id === 'Insurance') ? 'bg-blue-500 text-white' : 'bg-gray-200',
                  ].join(' ')}
                  onClick={() => {
                    setEditGroup(editContent?.pages || {});
                    setEditPage(editGroup?.Insurance || { id: 'Insurance' });
                  }}
                >
                  <i className="material-icons mr-1 inline-block font-bold">description</i>
                  Insurance Services
                </button>
              </li>
              <li>
                <button
                  className={[
                    'block',
                    'w-full',
                    'text-left',
                    'p-2',
                    'flex',
                    (editPage?.id === 'Benefits') ? 'bg-blue-500 text-white' : 'bg-gray-200',
                  ].join(' ')}
                  onClick={() => {
                    setEditGroup(editContent?.pages || {});
                    setEditPage(editGroup?.Benefits || { id: 'Benefits' });
                  }}
                >
                  <i className="material-icons mr-1 inline-block font-bold">description</i>
                  Nelcord Benefits
                </button>
              </li>
            </ul>
          )}
          {selectedGroup === BLOCKS && (
            <ul>
              <li>
                <button
                  className={[
                    'block',
                    'w-full',
                    'text-left',
                    'p-2',
                    'flex',
                    (editBlock?.id === 'ContactInfo') ? 'bg-blue-500 text-white' : 'bg-gray-200',
                  ].join(' ')}
                  onClick={() => {
                    setEditGroup(editContent?.blocks || {});
                    setEditBlock(editGroup?.ContactInfo || { id: 'ContactInfo' });
                  }}
                >
                  <i className="material-icons mr-1 inline-block font-bold">widgets</i>
                  Información de contacto
                </button>
              </li>
              <li>
                <button
                  className={[
                    'block',
                    'w-full',
                    'text-left',
                    'p-2',
                    'flex',
                    (editBlock?.id === 'ContactForm') ? 'bg-blue-500 text-white' : 'bg-gray-200',
                  ].join(' ')}
                  onClick={() => {
                    setEditGroup(editContent?.blocks || {});
                    setEditBlock(editGroup?.ContactForm || { id: 'ContactForm' });
                  }}
                >
                  <i className="material-icons mr-1 inline-block font-bold">widgets</i>
                  Formulario de contacto
                </button>
              </li>
              <li>
                <button
                  className={[
                    'block',
                    'w-full',
                    'text-left',
                    'p-2',
                    'flex',
                    (editBlock?.id === 'Footer') ? 'bg-blue-500 text-white' : 'bg-gray-200',
                  ].join(' ')}
                  onClick={() => {
                    setEditGroup(editContent?.blocks || {});
                    setEditBlock(editGroup?.Footer || { id: 'Footer' });
                  }}
                >
                  <i className="material-icons mr-1 inline-block font-bold">widgets</i>
                  Pie de página
                </button>
              </li>
              <li>
                <button
                  className={[
                    'block',
                    'w-full',
                    'text-left',
                    'p-2',
                    'flex',
                    (editBlock?.id === 'Page404') ? 'bg-blue-500 text-white' : 'bg-gray-200',
                  ].join(' ')}
                  onClick={() => {
                    setEditGroup(editContent?.blocks || {});
                    setEditBlock(editGroup?.Page404 || { id: 'Page404' });
                  }}
                >
                  <i className="material-icons mr-1 inline-block font-bold">widgets</i>
                  Página de error 404
                </button>
              </li>
            </ul>
          )}
          {selectedGroup === BUTTONS && (
            <ul>
              <li>
                <button
                  className={[
                    'block',
                    'w-full',
                    'text-left',
                    'p-2',
                    'flex',
                    (editButton?.id === 'RequestAdvisor') ? 'bg-blue-500 text-white' : 'bg-gray-200',
                  ].join(' ')}
                  onClick={() => {
                    setEditGroup(editContent?.buttons || {});
                    setEditButton(editGroup?.RequestAdvisor || { id: 'RequestAdvisor' });
                  }}
                >
                  <i className="material-icons mr-1 inline-block font-bold">smart_button</i>
                  Solicitar Asesor
                </button>
              </li>
              <li>
                <button
                  className={[
                    'block',
                    'w-full',
                    'text-left',
                    'p-2',
                    'flex',
                    (editButton?.id === 'MoreInfo') ? 'bg-blue-500 text-white' : 'bg-gray-200',
                  ].join(' ')}
                  onClick={() => {
                    setEditGroup(editContent?.buttons || {});
                    setEditButton(editGroup?.MoreInfo || { id: 'MoreInfo' });
                  }}
                >
                  <i className="material-icons mr-1 inline-block font-bold">smart_button</i>
                  Más Información
                </button>
              </li>
            </ul>
          )}
        </aside>

        <article className={[
          'w-8/12',
          'overflow-auto',
        ].join(' ')}>
          {!selectedGroup && (
            <h2 className={[
              'mt-4',
              'text-center',
              'text-2xl',
              'font-bold',
              'text-gray-400'
            ].join(' ')}>Bienvenido al panel de gestión</h2>
          )}
          {(selectedGroup === PAGES && !editPage) && (
            <h2 className={[
              'mt-4',
              'text-center',
              'text-2xl',
              'font-bold',
              'text-gray-400'
            ].join(' ')}>Selecciona una página para empezar a editar su contenido</h2>
          )}
          {(selectedGroup === BLOCKS && !editBlock) && (
            <h2 className={[
              'mt-4',
              'text-center',
              'text-2xl',
              'font-bold',
              'text-gray-400'
            ].join(' ')}>Selecciona un bloque para empezar a editar su contenido</h2>
          )}
          {(selectedGroup === BUTTONS && !editButton) && (
            <h2 className={[
              'mt-4',
              'text-center',
              'text-2xl',
              'font-bold',
              'text-gray-400'
            ].join(' ')}>Selecciona un botón para empezar a editar su contenido</h2>
          )}
          {(selectedGroup && (editPage || editBlock || editButton)) && (
            <header 
              className={[
                'flex',
                'justify-between',
                'items-center',
                'sticky',
                'py-4',
                'px-2',
                'top-0',
                'mx-2',
                'bg-white',
                'border-b',
              ].join(' ')}
            >
              <h1 className={[
                'my-4',
                'text-left',
                'text-2xl',
                'font-bold',
                'text-gray-800'
              ].join(' ')}>
                Edición de {editPage && 'página'}{editBlock && 'bloque'}{editButton && 'botón'}: {editPage && editPage.id}{editBlock && editBlock.id}{editButton && editButton.id}
              </h1>
              <nav className="flex gap-2">
                {/* Idiomas */}
                {languages.map((language, index) => (
                  <button
                  key={index}
                  className={[
                    'block',
                    'w-full',
                    'text-left',
                    'p-2',
                    'pl-4',
                    'flex',
                    'uppercase',
                    'border-l-2',
                    (editLanguage === language.code) ? 'text-blue-500' : 'text-gray-600',
                  ].join(' ')}
                  onClick={() => handleLanguage(language.code)}
                  >{language.code}</button>
                  ))}
                {/* <li>
                  <button onClick={() => newLanguage(language.code)}>+</button>
                </li> */}
                <FormActions form={formRef} saving={saving} onSubmit={handleAltSave} onCancel={handleCancel}/>
              </nav>
            </header>
          )}
          {(selectedGroup && editPage) && (
            <FormEditPage ref={formRef} data={editPage} saving={saving} onSaveChanges={(data) => handleSave(PAGES, data)} />
          )}
          {(selectedGroup && editBlock) && (
            <FormEditBlock ref={formRef} data={editBlock} saving={saving} onSaveChanges={(data) => handleSave(BLOCKS, data)} />
          )}
          {(selectedGroup && editButton) && (
            <FormEditButton ref={formRef} data={editButton} saving={saving} onSaveChanges={(data) => handleSave(BUTTONS, data)} />
          )}
        </article>
      </main>
      {/* <FormModal onSubmit={handleModalSubmit} data={{}}></FormModal> */}
    </section>
  )
}

export default AdminPanel;