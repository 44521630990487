import { forwardRef } from "react";
import MoreInfoEditForm from "./Forms/MoreInfoEditForm";
import RequestAdvisorEditForm from "./Forms/RequestAdvisorEditForm";

const FormEditButton = forwardRef(({ data, onSaveChanges }, ref) => {
  return (
    <>
      {data.id === "RequestAdvisor" && (
        <RequestAdvisorEditForm ref={ref} data={data} onSaveChanges={onSaveChanges}/>
      )}
      {data.id === "MoreInfo" && (
        <MoreInfoEditForm ref={ref} data={data} onSaveChanges={onSaveChanges} />
      )}
    </>
  );
});

export default FormEditButton;
