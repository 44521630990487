import objectPath from "object-path";
import { useEffect, useState } from "react";

const cleanString = (str) => {
  let strCopy = str;
  if (strCopy.length > 0) {
    strCopy = strCopy.replace(/\s+/g, " ");
    strCopy = strCopy.trim();
  }
  return strCopy;
};

const AdminInput = ({ type, label, isSaving, source, path, value, onChange, hidden }) => {
  const [data, setData] = useState(value);

  useEffect(() => {
    objectPath.set(source, path, cleanString(value));
    onChange(source);
  }, []);

  useEffect(() => {
    if (isSaving) {
      objectPath.set(source, path, value);
      onChange(source);
    }
    setData(cleanString(value));
  }, [isSaving, onChange, source, path, value]);

  useEffect(() => {
    objectPath.set(source, path, data);
    onChange(source);
  }, [onChange, source, path ,data]);

  const handleChange = (ev) => setData(ev.target.value);

  const handleBlur = (ev) => setData(cleanString(ev.target.value));

  return (
    <div className={[
      "flex",
      "flex-col",
      hidden && "hidden",
    ].join(" ")}>
      <label className="mb-2" htmlFor={label.toLowerCase().split(" ", "-")}>{label}</label>
      {type === "longtext" ? (
        <textarea className="p-2 mb-2 border-2"
          id={label.toLowerCase().split(" ", "-")}
          name={label.toLowerCase().split(" ", "-")}
          value={data}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      ) : (
        <input className="p-2 mb-2 border-2"
          type="text"
          id={label.toLowerCase().split(" ", "-")}
          name={label.toLowerCase().split(" ", "-")}
          value={data}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
    </div>
  )
};

export default AdminInput;
