import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import AdminBar from "./AdminBar";

const AdminTools = ({ loading, openPanel, onChange, allContent, languages, language, children }) => {
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (!user) {
        setSignedIn(false);
      }
      if (user && !signedIn) {
        setSignedIn(true);
      }
    });

    return () => {
      unsubscribe();
    }
  }, [signedIn]);
  
  return (
    <>
      {children}
      {(signedIn && !loading) && <AdminBar openPanel={openPanel} onChange={onChange} allContent={allContent} languages={languages} language={language} />}
    </>
  );
}

export default AdminTools;
