import { forwardRef } from "react";
import BenefitsEditForm from "./Forms/BenefitsEditForm";
import HomeEditForm from "./Forms/HomeEditForm";
import InsuranceEditForm from "./Forms/InsuranceEditForm";
import WealthEditForm from "./Forms/WealthEditForm";

const FormEditPage = forwardRef(({ data, onSaveChanges }, ref) => {
  return (
    <>
      {data.id === "Home" && (
        <HomeEditForm ref={ref} data={data} onSaveChanges={onSaveChanges}/>
      )}
      {data.id === "Wealth" && (
        <WealthEditForm ref={ref} data={data} onSaveChanges={onSaveChanges}/>
      )}
      {data.id === "Insurance" && (
        <InsuranceEditForm ref={ref} data={data} onSaveChanges={onSaveChanges}/>
      )}
      {data.id === "Benefits" && (
        <BenefitsEditForm ref={ref} data={data} onSaveChanges={onSaveChanges}/>
      )}
    </>
  );
});

export default FormEditPage;
