import { useCallback, useLayoutEffect, useState } from "react";
import useQuery from "../../hooks/useQuery";
import SelectList from "../FormControl/Controls/SelectList/SelectList";
import Input from "../FormControl/Controls/Input";

const Contact = ({ contactInfoTranslation, contactFormTranslation, pushContactInfo }) => {
  console.log(contactInfoTranslation, contactFormTranslation);

  let query = useQuery();

  const [contactInfo, setContactInfo] = useState({
    fullname: null,
    email: null,
    phone: null,
    subject: null,
    message: null,
  });

  const handleInputChange = useCallback((e) => {
    const fieldName = e.target.getAttribute('name');
    const fieldValue = e.target.value;

    setContactInfo({
      ...contactInfo,
      [fieldName]: fieldValue,
    });
  }, [contactInfo]);

  useLayoutEffect(() => {
    setContactInfo(pushContactInfo);
  }, [pushContactInfo])

  return (
    <>
      {(contactInfoTranslation || contactFormTranslation) && (
        <section id="contact" className={
          [
            "relative",
            "z-10",
            "bg-white",
          ].join(' ')
        }>
          <div className={[
            "lg:container",
            "mx-auto",
            "xs:p-8",
            "xl:p-16",
            "xl:flex",
            "xs:flex",
            "xs:flex-col",
          ].join(' ')}
          >
            <div className="flex xs:flex-col lg:flex-row">
              {contactInfoTranslation && (
                <div className="xs:w-full lg:w-2/6 xs:text-center lg:text-left xs:pt-8 lg:pt-0 lg:pr-16 leading-8 lg:border-r border-gray-600 xs:order-2 lg:order-1">
                  {contactInfoTranslation?.title && (
                    <h1
                      className="xs:text-4xl mb-8 flex items-end xs:justify-center lg:justify-start"
                      dangerouslySetInnerHTML={{ __html: contactInfoTranslation.title }}
                    />
                  )}
                  
                  {contactInfoTranslation?.description && (
                    <p
                      className="text-lg font-semibold pb-8"
                      dangerouslySetInnerHTML={{ __html: contactInfoTranslation.description }}
                    />
                  )}
                  
                  {(
                    contactInfoTranslation?.addressTitle
                    || contactInfoTranslation?.address
                    || contactInfoTranslation?.addressPlace
                    || contactInfoTranslation?.addressLocation
                    || contactInfoTranslation?.phoneTitle
                    || contactInfoTranslation?.phone
                  ) && (
                    <div className="pb-8">
                      <label
                        className="text-lg font-semibold"
                        dangerouslySetInnerHTML={{ __html: contactInfoTranslation.addressTitle }}
                      />
                      <p dangerouslySetInnerHTML={{ __html: contactInfoTranslation.address }} />
                      <p dangerouslySetInnerHTML={{ __html: contactInfoTranslation.addressPlace }} />
                      <p dangerouslySetInnerHTML={{ __html: contactInfoTranslation.addressLocation }} />
                      <p>
                        <span dangerouslySetInnerHTML={{ __html: contactInfoTranslation.phoneTitle }} /> <span dangerouslySetInnerHTML={{ __html: contactInfoTranslation.phoneNumber }} />
                      </p>
                    </div>
                  )}
                  
                  {(
                    contactInfoTranslation?.businessHoursTitle
                    || contactInfoTranslation?.businessHours
                    || contactInfoTranslation?.emailTitle
                    || contactInfoTranslation?.email
                  ) && (
                    <div>
                      <p
                        className="text-lg font-semibold"
                        dangerouslySetInnerHTML={{ __html: contactInfoTranslation.businessHoursTitle }}
                      />
                      <p dangerouslySetInnerHTML={{ __html: contactInfoTranslation.businessHours }} />
                      <p><span dangerouslySetInnerHTML={{ __html: contactInfoTranslation.emailTitle }} /> <span dangerouslySetInnerHTML={{ __html: contactInfoTranslation.email }} /></p>
                    </div>
                  )}
                </div>
              )}

              {contactFormTranslation && (
                <div id="contact-form" className="text-lg leading-8 xs:w-full lg:w-4/6 xs:pb-8 lg:pl-16 xs:border-b lg:border-0 border-gray-600 xs:order-1 lg:order-2">
                  <h2
                    className="text-2xl font-bold py-6 mb-6 border-b border-gray-600"
                    dangerouslySetInnerHTML={{ __html: contactFormTranslation.title }}
                    />
                  <h3
                    className="text-2xl font-bold uppercase py-4"
                    dangerouslySetInnerHTML={{ __html: contactFormTranslation.subtitle }}
                  />
                  {query.get("message") === "sent" && (
                    <p
                      className="bg-green-300 p-4 mb-4 border rounded-2xl text-center text-green-900"
                      dangerouslySetInnerHTML={{ __html: contactFormTranslation.messageSuccess }}
                    />
                  )}
                  {query.get("message") === "error" && (
                    <p
                      className="bg-red-300 p-4 mb-4 border rounded-2xl text-center text-red-900"
                      dangerouslySetInnerHTML={{ __html: contactFormTranslation.messageError }}
                    />
                  )}
                  <form action="contact.php" method="POST" className="xs:flex xs:flex-col md:grid md:grid-cols-2 md:gap-6 xs:space-y-4 md:space-y-0">
                    <Input
                      id="fullname"
                      label={contactFormTranslation.fields[0].label}
                      placeholder={contactFormTranslation.fields[0].placeholder}
                      value={contactInfo?.fullname}
                      onChange={handleInputChange}
                      error={(contactInfo?.fullname != null && contactInfo?.fullname.trim() === "") && {
                        message: contactFormTranslation.fields[0].errorText
                      }}
                    />
                    <Input
                      id="email"
                      label={contactFormTranslation.fields[1].label}
                      placeholder={contactFormTranslation.fields[1].placeholder}
                      value={contactInfo?.email}
                      onChange={handleInputChange}
                      error={(contactInfo?.email != null && contactInfo?.email.trim() === "") && {
                        message: contactFormTranslation.fields[1].errorText
                      }}
                    />
                    <Input
                      id="phone"
                      label={contactFormTranslation.fields[2].label}
                      placeholder={contactFormTranslation.fields[2].placeholder}
                      value={contactInfo?.phone}
                      onChange={handleInputChange}
                      error={(contactInfo?.phone != null && contactInfo?.phone.trim() === "") && {
                        message: contactFormTranslation.fields[2].errorText
                      }}
                    />
                    <div className="flex flex-col">
                      <label htmlFor="subject">{contactFormTranslation.fields[3].label}</label>
                      <SelectList
                        data-list="subjects"
                        name="subject"
                        id="subject"
                        value={contactInfo?.subject || ""}
                        placeholder={contactFormTranslation.fields[3].placeholder}
                        onChange={handleInputChange}
                        className={[
                          "w-full",
                          "bg-gray-200",
                          "py-1",
                          "px-4",
                          "rounded-full",
                          (contactInfo?.subject != null && contactInfo?.subject.trim() === "") ? "border-2 border-red-700" : "border",
                        ].join(" ")}
                        listClassName={[
                          "absolute",
                          "w-full",
                          "mt-2",
                          "rounded-xl",
                          "bg-gray-200",
                          "shadow-lg",
                          "hover:border-black border-2",
                        ].join(" ")}
                        listItemClassName="w-full px-4 py-2 text-left rounded-xl hover:bg-gray-300 "
                        iconSettings={{
                          size: "1rem",
                          top: "1/3rem",
                          right: "1rem",
                        }}
                        options={[
                          { key: "servicios-generales-nelcord", title: "Servicios Generales Nelcord", value: "Servicios Generales Nelcord" },
                          { key: "wealth-management", title: "Wealth Management", value: "Wealth Management" },
                          { key: "nelcord-life", title: "Nelcord Life", value: "Nelcord Life" },
                          { key: "nelcord-health", title: "Nelcord Health", value: "Nelcord Health" },
                          { key: "nelcord-savings", title: "Nelcord Savings", value: "Nelcord Savings" },
                          { key: "nelcord-benefits", title: "Nelcord Benefits", value: "Nelcord Benefits" },
                        ]}
                      ></SelectList>
                      {(contactInfo?.subject != null && contactInfo?.subject.trim() === "") && <p className={[
                        "text-xs",
                        "text-center",
                        "text-red-600",
                        "py-1",
                      ].join(" ")}>{contactFormTranslation.fields[3].errorText}</p>}
                    </div>
                    <div className="col-span-2 flex flex-col">
                      <label htmlFor="message">{contactFormTranslation.fields[4].label}</label>
                      <textarea
                        className={[
                          "bg-gray-200",
                          "p-4",
                          "rounded-3xl",
                          (contactInfo?.message != null && contactInfo?.message.trim() === "") ? "border-2 border-red-700" : "border",
                        ].join(" ")}
                        value={contactInfo?.message || ""}
                        placeholder={contactFormTranslation.fields[4].placeholder}
                        onChange={handleInputChange}
                        name="message"
                        id="message"
                      ></textarea>
                      {(contactInfo?.message != null && contactInfo?.message.trim() === "") && <p className={[
                        "text-xs",
                        "text-center",
                        "text-red-600",
                        "py-1",
                      ].join(" ")}>{contactFormTranslation.fields[3].errorText}</p>}
                    </div>
                    <div className="col-start-2 col-end-3">
                      <button
                        type="submit"
                        className="py-2 px-8 uppercase text-gray-600 border-gray-600 border rounded-full xs:w-full lg:w-auto lg:float-right"
                        dangerouslySetInnerHTML={{ __html: contactFormTranslation?.buttons?.[0]?.label || "Send"}}
                      />
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Contact;
