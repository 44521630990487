import { Fragment, useLayoutEffect } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import Contact from '../components/Contact/Contact';
import Hero from '../components/Hero/Hero';
import Section from '../components/Section/Section';
import NotFound from './NotFound';

const Home = ({ buttons, blocks, content }) => {
  useLayoutEffect(() => {
    const inViewport = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("inViewport", entry.isIntersecting);
        }
      });
    };

    const Obs = new IntersectionObserver(inViewport);
    const obsOptions = {
      threshold: 1
    };

    const elementsInViewport = document.querySelectorAll('[data-in-viewport]');

    elementsInViewport.forEach(element => {
      Obs.observe(element, obsOptions);
    });

    const slideElements = document.querySelectorAll('[data-slide]');
    const slideCounts = slideElements.length;

    if (slideCounts) {
      const slideElementsParent = slideElements[0].parentNode;

      setTimeout(switchSlides, 100);

      if (slideCounts > 1) {
        setInterval(switchSlides, 5000);
      }

      function switchSlides() {
        const activeSlide = slideElementsParent.querySelector('.active-in');
        const nextSlide = activeSlide?.nextSibling || slideElementsParent.querySelector('[data-slide]:first-child');

        activeSlide?.classList.add('active-out');
        activeSlide?.classList.remove('active-in');
        nextSlide?.classList.add('active-in');

        setTimeout(() => {
          activeSlide?.classList.remove('active-out');
        }, 1000);
      }
    }
  }, [content]);

  return (
    <>
      {!content && <NotFound content={blocks?.Page404} />}
      {content && (
        <>
          {(content?.Hero?.content?.[0]?.values?.length > 0) && (
            <Hero idRef={content?.idRef || 'hero'} titles={content?.Hero?.content?.[0]?.values} />
          )}

          {(
            content?.AboutUs?.title
            || content?.AboutUs?.description
            || content?.AboutUs?.content
          ) && (
            <Section id={content?.AboutUs?.idRef}
              background="bg-white"
              textColor="text-gray-600"
              disposition="grid"
            >
              {content?.AboutUs?.title && (
                <span className="logo-lg flex flex-col items-center xl:col-span-3" data-in-viewport="scale-in">
                  <i className="icon-Nelcord text-chroma xs:text-5xl xl:text-8xl mb-2" data-in-viewport="scale-in"></i>
                  <span className="uppercase xs:text-3xl xl:text-5xl font-semibold mb-2">Nelcord</span>
                  <span
                    className="text-sm font-thin"
                    dangerouslySetInnerHTML={{ __html: content?.AboutUs?.title }}
                  />
                </span>
              )}

              {content?.AboutUs?.description && (
                <q
                  className="xs:text-2xl xl:text-3xl xs:m-6 font-bold col-span-9 self-center"
                  data-in-viewport="scale-in"
                  dangerouslySetInnerHTML={{ __html: content?.AboutUs?.description }}
                />
              )}

              {content?.AboutUs?.content?.[0]?.value && (
                <p
                  className="col-span-12 p-6 text-base leading-8"
                  data-in-viewport="scale-in"
                  dangerouslySetInnerHTML={{ __html: content?.AboutUs?.content?.[0]?.value }}
                />
              )}
            </Section>
          )}

          {(
            content?.Goals?.title
            || content?.Goals?.description
          ) && (
            <Section id={content?.Goals?.idRef}
              title={content?.Goals?.title || ''}
              background="bg-chroma-green"
              textColor="text-white"
              disposition="flex"
              className="goals-pointer"
            >
              {content?.Goals?.description && (
                <p
                  className="text-lg leading-8 text-white w-auto xs:pt-8 xl:pt-0 xl:pl-16 self-center"
                  data-in-viewport="scale-in"
                  dangerouslySetInnerHTML={{ __html: content?.Goals?.description }}
                />
              )}
            </Section>
          )}

          {content?.Goals?.content?.length > 0 && (
            <Section
              background="bg-white"
              textColor="text-gray-600"
            >
              <div className="xs:flex-col sm:flex sm:flex-row sm:flex-wrap items-start justify-center sm:line-between sm:line-height-20 lg:line-between lg:line-height-20">
                {content.Goals.content.map((goal, index) => 
                  (goal.title || goal.description) && (
                    <div key={index} className={[
                      "xs:w-full",
                      "sm:w-1/2",
                      "lg:w-1/3",
                      "flex",
                      "flex-col",
                      "items-center",
                      "xs:p-8",
                      "md:p-16",
                      goal.class || '',
                    ].join(" ")} data-in-viewport="scale-in">
                      {goal.icon && (
                        <i className={[
                          "text-8xl",
                          "mb-4",
                          goal.icon || '',
                        ].join(" ")} data-in-viewport="scale-in"></i>
                      )}

                      {goal.title && (
                        <h2
                          className="text-2xl text-center font-bold mb-4"
                          dangerouslySetInnerHTML={{ __html: goal.title }}
                        />
                      )}

                      {goal.description && (
                        <p
                          className="text-sm leading-8 text-center"
                          dangerouslySetInnerHTML={{ __html: goal.description }}
                        />
                      )}
                    </div>
                  )
                )}
              </div>
            </Section>
          )}

          {(
            content?.Wealth?.title
            || content?.Wealth?.description
            || content?.Wealth?.content.length
          ) && (
            <Section id={content.Wealth.idRef}
              background="bg-chroma-violet"
              textColor="text-white"
            >
              {content.Wealth.title && (
                <h1
                  className="xs:text-3xl lg:text-5xl xs:text-center lg:text-left font-bold mb-8"
                  data-in-viewport="scale-in"
                  dangerouslySetInnerHTML={{ __html: content.Wealth.title }}
                />
              )}

              {content?.Wealth?.content && (
                <div className="flex xs:flex-col lg:flex-row">
                  <i className="xs:block lg:hidden icon-Nelcord text-10xl xs:w-full lg:w-1/2 xs:text-center xs:mb-8 lg:pr-8 xs:text-stock-background" data-in-viewport="scale-in"></i>
                  <i className="xs:hidden lg:block icon-Nelcord text-20xl xs:w-full lg:w-1/2 xs:text-center xs:mb-8 lg:pr-8 text-stock-background" data-in-viewport="scale-in"></i>
                  <div className="text-lg leading-8 text-white w-auto lg:pl-8 self-center" data-in-viewport="scale-in">
                    {content.Wealth.content.map((item, index) => (
                      (
                        item?.value
                        || item?.values?.length
                      ) &&  (
                        <Fragment key={index}>
                          {(
                            item.type === "paragraph"
                            && item.value
                          ) && (
                            <p
                              key={index}
                              className="mb-4"
                              data-in-viewport="scale-in"
                              dangerouslySetInnerHTML={{ __html: item.value }}
                            />
                          )}
                          {(item.type === "list" && item?.values?.length) && (
                            <ul className="list-disc ml-8 mb-4">
                              {item.values.map((listItem, listIndex) =>
                                listItem && (
                                  <li
                                    key={listIndex}
                                    className="list-item"
                                    data-in-viewport="scale-in"
                                    dangerouslySetInnerHTML={{ __html: listItem }}
                                  />
                                )
                              )}
                            </ul>
                          )}
                        </Fragment>
                      )
                    ))}
                    <NavHashLink to="/wealth-management#wealth-management-more-info"
                      className="py-2 px-8 uppercase border rounded-full xs:block xs:w-full md:inline md:w-auto text-center md:float-right"
                      data-in-viewport="scale-in"
                      dangerouslySetInnerHTML={ { __html: buttons?.MoreInfo?.text } }
                    />
                  </div>
                </div>
              )}
            </Section>
          )}

          {(
            content?.DoItYourself?.title
            || content?.DoItYourself?.description
           ) && (
            <Section id={content.DoItYourself.idRef}
              background="bg-white"
              textCollor="text-gray-600"
              disposition="flex"
            >
              <i className="xs:hidden xl:block icon-User-rotation text-20xl pr-8 text-chroma-cold" data-in-viewport="fade-rotate" />
              <div className="leading-8 w-auto xs:p-0 lg:pl-8 self-center overflow-auto">
                <i className="xs:hidden lg:block xl:hidden icon-User-rotation text-10xl pr-8 float-left text-chroma-cold" data-in-viewport="scale-in"></i>
                {content.DoItYourself.title && (
                  <h1
                    className="xs:text-4xl lg:text-6xl lg:leading-relaxed xs:text-center lg:text-left text-purple-800 font-bold uppercase mb-8 leading-relaxed"
                    data-in-viewport="scale-in"
                    dangerouslySetInnerHTML={{ __html: content.DoItYourself.title }}
                  />
                )}
                <i className="xs:block lg:hidden icon-User-rotation text-10xl text-center w-full pb-8 text-chroma-cold" data-in-viewport="scale-in"></i>
                {content.DoItYourself.description && (
                  <p
                    className="text-base mb-4 leading-7"
                    data-in-viewport="scale-in"
                    dangerouslySetInnerHTML={{ __html: content.DoItYourself.description }}
                  />
                )}
              </div>
            </Section>
          )}

          {(
            content?.Insurance?.title
            || content?.Insurance?.description
            || content?.Insurance?.content?.length > 0
            || content?.Insurance?.content?.[0]?.content.length > 0
            || content?.Insurance?.content?.[0]?.content?.[0]?.value
          ) && (
            <Section id={content?.Insurance?.idRef}
              background="bg-chroma-red"
              textColor="text-white"
            >
              {content.Insurance.title && (
                <h1
                  className="xs:text-3xl lg:text-5xl xs:text-center lg:text-left font-bold mb-8"
                  data-in-viewport="scale-in"
                  dangerouslySetInnerHTML={{ __html: content.Insurance.title }}
                />
              )}

              {content.Insurance.description && (
                <div className="flex xs:flex-col lg:flex-row xs:mb-8 lg:mb-0">
                  <i className="xs:block lg:hidden icon-Nelcord text-10xl xs:w-full lg:w-2/5 xs:text-center xs:mb-8 lg:mb-0 lg:pr-16 xs:text-family-background" data-in-viewport="scale-in"></i>
                  <i className="xs:hidden lg:block icon-Nelcord text-xxl xs:w-full lg:w-2/5 xs:text-center xs:mb-8 lg:mb-0 lg:pr-16 text-family-background" data-in-viewport="scale-in"></i>

                  <div className="text-base leading-8 text-white xs:w-full lg:w-3/5 pb-12 self-center border-b">
                    <p
                      className="mb-8"
                      data-in-viewport="scale-in"
                      dangerouslySetInnerHTML={{ __html: content.Insurance.description }}
                    />
                  </div>
                </div>
              )}

              {content.Insurance.content?.length > 0
                && content.Insurance.content.map((item, index) => {
                return (
                  <>
                    {(
                      item.content?.length > 0
                      && item.content.reduce((acc, curr) => (curr.value ? true : false), false)
                    ) && (
                      <div key={index} className={[
                        "xs:mb-8",
                        "xs:pb-8",
                        "lg:mb-0",
                        "lg:pb-0",
                        content.Insurance.content.length -1 !== index ? "border-b" : "",
                      ].join(" ")}>
                        <div className="flex xs:flex-col lg:flex-row pt-8 pb-4">
                          <h2 className="uppercase font-bold xs:text-4xl sm:text-5xl xs:w-full lg:w-2/5 xs:mb-2 sm:mb-1 lg:pr-16 self-center flex items-center" data-in-viewport="scale-in">
                            Nelcord <i className={[
                              "font-normal",
                              "ml-2",
                              item.class || ''
                            ].join(" ")} />
                          </h2>
                          {item.content.length && (
                            <div className="text-base leading-8 text-white xs:w-full lg:w-3/5 xs:pb-8 lg:py-4 self-center" data-in-viewport="scale-in">
                              {item.content.map((paragraph, index) => (
                                <p
                                  key={index}
                                  className="mb-4"
                                  dangerouslySetInnerHTML={{ __html: paragraph.value }}
                                />
                              ))}
                            </div>
                          )}
                        </div>
                        <div className={[
                          "xs:block",
                          "text-right",
                          content.Insurance.content.length -1 !== index ? "lg:pb-12" : "",
                        ].join(" ")}>
                          <NavHashLink to={`/insurance-services#${item.idRef}`}
                            className="py-2 px-8 uppercase border rounded-full xs:block xs:w-full md:inline md:w-auto text-center xs:mb-4 md:mr-4 md:mb-0"
                            data-in-viewport="scale-in"
                            dangerouslySetInnerHTML={ { __html: buttons?.MoreInfo?.text } }
                          />
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </Section>
          )}

          <Contact contactInfoTranslation={blocks?.ContactInfo} contactFormTranslation={blocks?.ContactForm} />
        </>
      )}
    </>
  );
}

export default Home;